.controlpanel {
    @include flex(column, flex-end, center);
    width: 100%;
    padding: 0 vw(25) vw(34);
    position: relative;
    z-index: 4;
    
    @include breakpoint(not-desktop) {
        padding: 0;
        margin-top: vw(10, phone);
    }

    &__info {
        @include font-size(12);
        position: absolute;
        letter-spacing: vw(0.7);
        top: 50%;
        transform: translateY(-50%);
        left: vw(37);
        color: $color-graphite;
        opacity: 0;
        text-transform: uppercase;
        z-index: -1;
        transition: opacity 0.3s $ease-out;

        &.is-visible {
            opacity: 1;
        }
    }

    &__timeline {
        @include flex(row, flex-start, center);
        @include z-index(timeline, $panel-elements);
        width: 100%;
        height: vw(36);
        border-radius: 6px;
        // border: 1px solid $color-orange;
        position: relative;
        // overflow: hidden;
        
        @include breakpoint(not-desktop) {
            height: vw(36, phone);
        }
    }

    &__title {
        @include font-size(16);
        @include z-index(title, $panel-elements);
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;

        strong {
            font-weight: 900;
        }
    }

    &__autoplay {
        position: relative;
        width: vw(100);
        height: vw(36);
        border-radius: 6px;
        background-color: $color-orange;
        transition: all 0.3s $ease-out;
        justify-self: flex-end;
        
        @include breakpoint(not-desktop) {
            width: vw(95, phone);
            height: vw(36, phone);
        }

        &.is-playing {
            border: 1px solid $color-orange;
            background: $color-white;

            span {
                color: $color-orange;
            }
        }

        span {
            @include font-size(18);
            font-weight: 700;
            top: 46%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            color: $color-white;

            @include breakpoint(not-desktop) {
                top: 48%;
            }
        }
    }

    &__info {
        width: 100%;
        pointer-events: auto;
        z-index: 2;

        @include breakpoint(not-desktop) {
            left: vw(35, phone);
            color: $color-tooltip;
        }
    }

    &__range {
        flex: 1;

        input {
            width: 100%;
        }

        &-widget {
            position: absolute;
            top: vw(48, phone);
            left: vw(-20, phone);
            visibility: hidden;
            
            &.is-visible {
                visibility: visible;
            }

            @include breakpoint(desktop) {
                display: none;
            }
        }
    }

    &__value {
        @include flex(row, center, center);
        // margin-left: 32px;
        @include font-size(16, 17);
        letter-spacing: -0.3px;
        margin-left: auto;
        margin-right: 0;

        .legend & {
            margin-left: 0;
            margin-right: auto;
        }

        svg {
            width: vw(21);
            margin-right: 7px;

            @include breakpoint(not-desktop) {
                margin-right: 4px;
                margin-bottom: 3px;
                width: vw(10, phone);
            }

            .legend & {
                width: vw(10);

                @include breakpoint(not-desktop) {
                    width: vw(10, phone);
                }
            }
        }

        strong {
            margin-right: 3px;
        }

        @include breakpoint(desktop) {

            .dropdown__item &,
            .bar & {
                justify-content: space-between;
                min-width: vw(152);
            }
        }
    }

    &__inner {
        width: 100%;
        @include flex(row, space-between, center);
        pointer-events: auto;
        position: relative;
        gap: vw(15);
        z-index: 2;
        
        @include breakpoint(not-desktop) {
            gap: vw(10, phone);
        }

        body.is-plumes-loading & {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__zone {
        position: absolute;
        inset: auto 0 0 0;
        width: 100%;
        height: 140px;
        background-color: transparent;
        pointer-events: auto;
        bottom: -40px;
    }

    &__date {
        @include font-size(16);
        margin-left: 0;
        gap: vw(12);
        background: none;
        color: $color-text;
        visibility: visible;

        svg {
            position: absolute;
            right: vw(12);
            width: vw(15);
            transition: $chevron-transition;
        }
    }

    .date {
        @include flex(row, flex-start, center);
        @include font-size(18);
        padding-left: vw(12);
        height: 100%;

        @include hover {
            svg {
                transform: $chevron-hover;
            }
        }
    }
}