
.scroller {
    @include flex();
    position: absolute;
    inset: 0 1px 0 auto;
    width: vw(6);
    height: 100%;
    // padding: 16px 0 21px + 16px;

    &__inner {
        @include flex(row, center, flex-start);
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__toggle {
        width: vw(6);
        height: vw(49);
        border-radius: 6px;
        background-color: $color-scroller-toggle;
        position: relative;
        z-index: 1;
    }

    &__stroke {
        width: vw(6);
        height: calc(100% - vw(2));
        background-color: $color-scroller-stroke;
        position: absolute;
        inset: 0;
        margin: auto;
        border-radius: 0 0 6px 0;
    }
}