.svg-hamburger {
    width: vw(37);
}

.svg-dropdown-2 {
    width: vw(10);
    
    @include breakpoint(not-desktop) {
        width: vw(10, phone);
    }

    path {
        stroke: $color-orange;
    }
}

.svg-download {
    width: vw(13);

    path {
        stroke: $color-orange;
    }
}

.svg-dropdown {
    path {
        stroke: $color-graphite;
    }
}

.svg-loader {
    width: 60px;
    height: 60px;

    animation: spin 1s infinite linear;
    animation-play-state: paused;

    circle {
        stroke: currentColor;
        stroke-dashoffset: 182px;
        stroke-dasharray: 182px;
        animation: draw-loader 2s infinite ease-in-out;
        animation-play-state: paused;
    }
}
