.dropdown {
    width: 100%;
    position: relative;

    &--bar {
        height: 100%;
        
        .dropdown__inner {
            padding: vw(10) 0 0 vw(13);
            background: $color-white;
            top: 0;
            height: 100%;
            border-radius: 0 0 6px 6px;
            border: 1px solid $color-graphite;
        }
    }

    &--hours {
        position: absolute;
        top: vw(110);
        left: vw(15);
        width: calc(100% - vw(30));

        @include breakpoint(not-desktop) {
            top: vw(70, phone);
            left: vw(15, phone);
            width: calc(100% - vw(30, phone));
        }
    }

    &__skip {
        position: absolute;
        bottom: -35px;
        right: 0;
        text-decoration: underline;
        transition: opacity .3s;

        @include hover {
            opacity: .4;
        }
    }

    &__item {
        @include flex(row, space-between, center);
        color: $color-graphite;
        padding: vw(4) 0;
        width: 100%;
        pointer-events: auto;
        position: relative;

        &:before, &:after {
            display: none;
        }

        .date {
            border: none;
            padding: 0 !important;

            .date__hour, .date__date {
                transition: color 0.3s $ease-out;
            }

            svg {
                display: none;
            }
        }

        &:first-of-type {
            padding-top: vw(8);
        }

        &:last-of-type {
            padding-bottom: vw(12);
        }

        button {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
        }

        @include hover {
            .date__hour, .date__date {
                color: $color-orange;
            }
        }

        @include breakpoint(phone) {
            @include flex(column, center, flex-start);
            padding: 6px 0;
        }
    }

    &__value {
        @include flex(row, space-between, center);
        background: $color-white;
        color: $color-graphite;
        height: vw(36);
        border-radius: 6px;
        border: 1px solid $color-graphite;
        width: 100%;
        position: relative;
        z-index: 1;
        padding: 0 vw(10) 0 vw(13);
        @include font-size(18, 1);
        font-weight: 700;
        
        @include breakpoint(not-desktop) {
            padding: 0 vw(15, phone) 0 vw(13, phone);
            height: vw(44, phone);
        }
        
        @include breakpoint(tablet) {
            @include font-size(18, 1);
            padding: 0 vw(15, tablet) vw(3, tablet) vw(18, tablet);
            height: vw(55, tablet);
            border-radius: 12px;
        }

        svg {
            width: vw(15);
            transition: $chevron-transition;

            @include breakpoint(not-desktop) {
                width: vw(15, phone);
            }
            
            @include breakpoint(tablet) {
                width: vw(20, tablet);
            }
        }

        @include hover {
            svg {
                transform: $chevron-hover;
            }
        }

        .dropdown.is-open & {
            svg {
                transform: translatey(5px);
            }
        }

        .dropdown--hours & {
            padding: 0 vw(13) 0 vw(13);
            @include font-size(13, 13);
            // color: $color-gray-list;
            transition: color .4s $ease-custom;
            
            @include hover {
                color: $color-gray-list;
                svg {
                    transform: translatey(0px);
                }
            }

            @include breakpoint(not-desktop) {
                padding: 0 vw(13, phone) 0 vw(13, phone);
            }
            
            @include breakpoint(tablet) {
                padding: 0 vw(13, tablet) 0 vw(13, tablet);
                @include font-size(16, 20);
            }

        }
        .dropdown--hours.is-open & {
            color: $color-graphite;
            svg {
                transform: translatey(0);
            }
        }
    }


    &__inner {
        border-radius: vw(8);
        overflow-y: scroll;
        position: absolute;
        inset: calc(100% - 8px) 0 auto;
        width: 100%;
        pointer-events: none;
        transform: translateZ(0);

        .dropdown.is-open & {
            pointer-events: auto;
        }

        .calendar & {
            max-height: 200px;
            overflow-y: auto;
        }
    }


    &__list {
        @include font-size(16, 1);
        color: $color-gray-medium;
        list-style-type: none;
        padding: vw(10) 0 0 0;
        border-top: 8px solid $color-gray-light;
        transition: transform 0.65s $ease-custom;
        transform: translateY(-100%);
        background: $color-white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid $color-graphite;
        padding-bottom: vw(5);
        overflow: hidden;

        .dropdown.is-open & {
            transform: translateY(0%);
        }



        li {

            button {
                background-color: $color-white;
                transition: color 0.4s $ease-custom;
                padding: vw(5) vw(10);
                min-height: vw(28);
                width: 100%;
                text-align: left;
                color: $color-graphite;

                @include hover {
                    color: $color-orange;
                }

                &[disabled] {
                    pointer-events: none;
                    color: $color-gray-light-2;
                }

                @include breakpoint(not-desktop) {
                    min-height: vw(28, phone);
                    padding: vw(7, phone) vw(10);
                }

                @include breakpoint(tablet) {
                    min-height: vw(28, tablet);
                    padding: vw(12, tablet) 0;
                    @include font-size(18, 1);
                }
            }

            &:last-of-type {

                span {
                    position: relative;
                    top: -1 * vw(1);
                }
            }
        }

        @include breakpoint(not-desktop) {
            padding: vw(9, phone) vw(13, phone);
        }

        @include breakpoint(tablet) {
            padding: vw(12, tablet) vw(18, tablet);
        }
    }
}
