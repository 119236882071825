$size-hd: 1600px;
$size-desktop: 1024px;
$size-tablet: 660px;
$size-min: 320px;

$size-laptop-height: 640px;

$margin: 25px;
$mobile-margin: 28px;

$menu: 370px;
$header-height: 84px;

$hamburger-width: 28px;

$panel: 394px;

$extra-width: 429px;
$extra-height: 332px;