.inputrange {
    height: 100%;
    background: $color-gray-light-3;
    border-radius: 6px;

    input {
        position: relative;
        -webkit-appearance: none;
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        cursor: pointer;
        z-index: 2;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: vw(24);
            height: vw(36);
            border-radius: 6px;
            background: $color-orange;
            background-image: url('../../assets/svg/move.svg');
            background-repeat: no-repeat;
            background-size: vw(8);
            background-position: 48% 53%;
            transition: background-color 0.6s $ease-out;
            cursor: grab;
            
            @include breakpoint(not-desktop) {
                height: vw(36, phone);
                width: vw(24, phone);
                background-size: vw(7, phone);
                box-shadow: none;
            }
        }
    }
}
