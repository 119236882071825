:root {
    --app-height:   100%;
    --extra-height: 50px;
    --menu-height:  20px;
}

main {
    @include z-index(content);
    min-height: var(--app-height);
    position: relative;
    overflow: hidden;
    scrollbar-width: none;
    transition: transform 1s $ease-custom;

    body.is-map-usable &,
    body.is-map-usable-single &,
    body.is-menu-open & {
        overflow-y: hidden;
    }
    body.is-map-usable & {
        pointer-events: none;
    }
}

::-webkit-scrollbar {
    width: 0;
}

html,
body {
    min-height: 100%;
    height: 100%;
    color: $color-text;
    background-color: $color-bg;

    @include breakpoint(not-desktop) {
        &.is-menu-open {
            touch-action: none;
        }
    }

    &.is-full-height {
        overflow: hidden;

        main {
            overflow-y: hidden;
        }
    }

    &.is-menu-open {

        main {
            transform: translateY(#{var(--menu-height)});
        }

    }
}

html {

    @include breakpoint(desktop) {
        font-size: vw(14px);
    }
}

body {
    @include font-family(sans);
    color: $color-text;
}

#content {
    height: 100%;
}

.content {
    position: relative;
    @include z-index(content);
}

// debug dat gui
.dg.ac {
    z-index: 100 !important;
    display: none;
}

[aria-hidden="true"] {
    // visibility: hidden;
}