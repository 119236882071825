.accordion {

    &__item {
        margin-bottom: vw(15);
        border-bottom: 1px solid rgba($color: $color-gray-medium, $alpha: 1);
        overflow: hidden;
        .wrap {
            position: relative;
            min-height: vw(33);

            @include breakpoint(not-desktop) {
                padding: vw(10, phone) 0;
            }

            @include breakpoint(tablet) {
                padding: vw(10, tablet) 0;
            }
        }

        p {
            padding-right: 100px;
            font-weight: 700;
            @include font-size(18, 20);
            padding-bottom: vw(15);
            transition: color $time-fast $ease-custom;

            @include breakpoint(phone) {
                padding-right: 40px;
            }

            @include breakpoint(tablet) {
                padding-right: 40px;   
            }
        }

        ul {
            padding-bottom: 20px;
        }

        a { white-space: nowrap;}

        .nested p,
        .nested li {
            font-weight: 400;
            @include font-size(14, 18);
        }
    }



    &__button {
        @include flex(row, center, center);
        color: $color-graphite;
        position: absolute;
        top: 0;
        right: 0;
        width: 34px;
        height: 34px;
    }



    &__toggle {
        @include full;
        z-index: 1;

        &:hover {
            ~ .accordion__button .icon-dropdown { @extend %icon-dropdown-hover !optional; }
        }

        &[aria-expanded="true"] {
            ~ .accordion__button {
                color: $color-orange;
                .icon-dropdown {transform: rotate(180deg); @extend %icon-dropdown-open !optional;}
            }
            + p {color: $color-orange;}
        }
    }
}
