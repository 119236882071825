.test-heading {
    @include flex(row, flex-start, center);
    height: vw(44);
    
    @include breakpoint(not-desktop) {
        @include flex(column, flex-start, flex-start);
        position: relative !important;
        height: auto;

        &__row {
            width: 100%;
            @include flex(row, space-between, center);
            margin-top: vw(7, phone);
        }
    }
    
    @include breakpoint(talbet) {
        &__row {
            margin-top: vw(7, tablet);
        }
    }

    p {
        @include font-size(14, 1);
        white-space: nowrap;
    }

    h2 {
        @include font-size(24);
        padding: 0;
        white-space: nowrap;
    }

    span {
        @include font-size(14);
        font-weight: 400;
        color: $color-text;
    }

    &__details {
        @include flex(row, flex-start, center);
        gap: vw(16, phone);
        
        @include breakpoint(tablet) {
            gap: vw(16, tablet);
        }
    }

    .decoration {
        top: -25%;
        left: vw(7);
        height: 120%;
    }

    .text {
        margin: 0;
        padding: 0 vw(25);

        @include breakpoint(desktop) {
            &:not(:first-of-type) {
                border-left: 1px solid $color-panel-text;
            }
        }

        @include breakpoint(not-desktop) {

            padding: 0;

            p {
                @include font-size(12);
            }
            
            h2 {
                @include font-size(18);
            }
            
            span {
                @include font-size(12);
                vertical-align: bottom;
            }
        }
    }
}