.header {
    @include z-index(header);
    position: absolute;
    inset: 0 0 auto 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: vw($margin);
    pointer-events: none;

    body:not(.is-loaded) & {
        * {
            pointer-events: none !important;
        }

        .toggle {
            visibility: hidden;
        }
    }

    @include breakpoint(not-desktop) {
        height: $header-height;
        padding: vw(36, phone) vw(20, phone) vw(14, phone);
        background-color: $color-white;
        transition: background-color 0.2s linear 0.4s, transform 0.6s $ease-custom 0.2s;
        box-shadow: vw(1, phone) vw(1, phone) vw(2, phone) $color-gray;
        
        &.is-hidden {
            &:not(body.is-menu-open &) {
                transform: translateY(-100%);
            }
        }

        body.is-mapview-mobile &, body.is-mobile-zoomed & {
            transition: background-color 0.2s linear 0s, transform 0.6s $ease-custom 0s;;
            //background-color: transparent;
        }

        body.is-menu-open & {
            transition: background-color 0.2s linear 0.1s, transform 0.6s $ease-custom 0.2s;;
            //background-color: transparent;
        }
    }

    @include breakpoint(tablet) {
        padding: 24px;
    }

    &__toggle {
        position: relative;
    }

    &__logo {
        pointer-events: auto;
        width: vw(180);

        svg {
            overflow: visible;

            .atom {
                transition: transform 0.8s $ease-custom;
                transform-origin: 88.7% 51.5%;
            }
        }

        @include breakpoint(not-desktop) {
            width: auto;

            svg {
                width: vw(140, phone);
                height: auto;
            }
        }

        @include breakpoint(tablet) {
            svg {
                width: vw(150, tablet);
            }
        }

        // body.is-loaded & {
        //   transform: scale(1);
        //}

        @include hover {
            svg .atom {
                transform: rotate(180deg);
            }
        }
    }


    &__zone {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: -20px 0 0 0;
        pointer-events: none;

        @include breakpoint(not-desktop) {
            height: 200%;
        }
    }
}
