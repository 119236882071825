$stroke-viewfinder: 617;
$stroke-list: 711;

.button {
    @include breakpoint(desktop) {
        @include flex(row, flex-start, center);
        position: relative;
        padding: vw(5) vw(8) vw(5) vw(8);
        width: fit-content;
        height: vw(34);
        font-family: $font-sans;
        background-color: $color-orange;
        border-radius: vw(6);
        margin: 0 auto;
        
        &--text {
            display: inline-block;
            margin-left: 0;
        }
    }
    
    
    &::before,
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        transition: all 0.55s $ease-custom;
    }
    
    &::after {
        transform: scale(1.35);
        opacity: 0;
        z-index: 2;
    }
    
    @include hover {
        
        &::before {
            opacity: 0;
        }
        
        &::after {
            transform: scale(1);
            opacity: 1;
        }
    }
    
    
    @include breakpoint(not-desktop) {
        @include font-size(14);
        @include flex();
        height: vw(36, phone);
        background: $color-orange;
        border-radius: 6px;
        width: fit-content;
        margin: vw(60) auto 0;
        padding: vw(12) vw(16) vw(14);
    }
    
    @include breakpoint(tablet) {
        height: vw(36, tablet);
    }
    
    
    
    span:not(.button__border) {
        @include font-size(14, 18);
        padding-bottom: 1px;
        margin: 0 10px;
        color: $color-white;
        font-weight: 800;
        transition: color 0.3s $ease-out;

        @include breakpoint(not-desktop) {
            padding-top: 0;
        }

        @include breakpoint(phone-small) {
            @include font-size(22);
        }
    }

    &--border {
        background: transparent;
        border: 1px solid $color-orange;

        span {
            color: $color-orange !important;
        }
    }
}