.page {


    &__heading {
        @include breakpoint(desktop) {
            max-width: vw(160);
            position: fixed;
            left: vw($margin);
            top: calc(#{$header-height} + 25px);
        }

        @include breakpoint(hd) {
            top: calc(#{$header-height} + 75px);
        }

        @include breakpoint(not-desktop) {
            margin-bottom: vw(150, phone);
        }

        @include breakpoint(tablet) {
            margin-bottom: vw(150, tablet);
        }
    }


    &__side-image {
        width: vw(270);
        margin-top: vw(130);

        @include breakpoint(not-desktop) {
            display: none;
        }
    }


    &__wrap {
        display: grid;
        grid-template-columns: vw(550) vw(270);
        column-gap: vw(80);
        padding-bottom: 100px;

        @include breakpoint(not-desktop) {
            display: block;
            width: 100%;
        }
    }


    &__link {
        text-decoration: underline;
    }


    &__wrapper {
        @include flex(column, flex-start, center);
        padding-top: calc(#{$header-height} + 30px);
        overflow-y: auto;
        position: relative;
        width: 100%;
        height: 100vh;
        background-color: $color-bg;

        @include breakpoint(hd) {
            padding-top: calc(#{$header-height} + 80px);

        }

        @include breakpoint(not-desktop) {
            padding: 100px 18px 50px;
            align-items: flex-start;
        }

        @include breakpoint(tablet) {
            padding: calc(#{$header-height} + 60px) vw(72, tablet);
        }

        h2, h3 {
            padding-left: 0;
        }
    }

    &__mobile-decoration {
        @include breakpoint(desktop) {
            display: none;
        }
    }

    &__bg {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        opacity: 0;
        animation: fadeVideo 1s linear 0.3s;
        animation-fill-mode: forwards;

        video {
            filter: sepia(20%);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include breakpoint(not-desktop) {
                height: 100vh;
                object-position: 29% 50%;
            }
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-bg;
            opacity: 0.7;
        }
    }

    &__content {


        @include breakpoint(desktop) {
            > * {
                & + * {
                    margin-top: vw(30);
                }
            }
        }

        @include breakpoint(not-desktop) {

            border-top: 1px solid $color-calendar-bg;
            padding-top: vw(15, phone);

            h3 {
                @include font-size(24, 26);
                margin-bottom: vw(20, phone);
            }

            .button {
                margin-top: vw(30, phone);
                margin-left: 0;
            }

            .image {
                margin: vw(50, phone) 0;
            }
        }
    }

    &__description {
        @include breakpoint(desktop) {
            margin: 30px 0 0 20px;
        }
    }

    &__image {
        position: relative;
        width: 223px;
        height: 170px;
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
        margin-left: 20px;
        box-shadow: 0 4px 13px rgba(0, 0, 0, 0.14);

        img {
            position: absolute;
            object-fit: cover;
            width: auto;
            height: 125%;
            object-position: 100%;
        }
    }
}
