$menu-decoration-size: 47px;

.menu {
    @include z-index(menu);
    position: absolute;
    inset: 0 0 auto;
    background-color: $color-menu-bg;
    width: 100%;
    overflow: hidden;
    transition: transform 1s $ease-custom;
    transform: translateY(calc(-100% - vw(2)));
    box-shadow: vw(1) vw(1) vw(2) $color-gray;

    body.is-menu-open & {
        transform: translateY(0);
    }

    @include breakpoint(not-desktop) {
        @include z-index(panel);
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;

        img {
            position: relative;
            top: -120px;
            width: 100%;
        }

        @include breakpoint(not-desktop) {
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -10%);
                max-width: unset;
                height: unset;
                width: auto;
            }
        }
    }

    &__inner {
        @include flex(column, flex-start, flex-end);
        width: 100%;
        position: relative;
        padding: $margin - 8px vw(70) $margin 0;
        z-index: 1;

        @include breakpoint(not-desktop) {
            height: calc(100vh - $header-height);
            padding: 120px 20px 30px 20px;
        }
    }

    &__footnote {
        @include font-size(11, 12);
        position: absolute;
        inset: auto auto 12px $margin;
        color: $color-menu-footnote;

        @include breakpoint(not-desktop) {
            inset: none;
            bottom: 12px;
            left: 18px;
        }
    }

    &__logo {
        position: absolute;
        pointer-events: auto;
        padding: 34px;

        svg {
            width: 214px;
        }

        @include breakpoint(phone) {
            padding: 18px;

            svg {
                width: 171px;
                height: 48px;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        padding: 34px 34px 0 0;

        @include breakpoint(phone) {
            padding: 18px;
        }
    }

    nav {
        display: block;

        @include breakpoint(desktop) {
            margin-top: vw(45);
        }
    }

    &__list {
        @include flex(column, flex-start, flex-end);
        gap: 6px;
        list-style: none;
        text-align: right;

        @include breakpoint(not-desktop) {
            gap: vw(15, phone);
        }

        @include breakpoint(tablet) {
            gap: vw(15, tablet);
        }

        li {
            @include font-size(24, 1);
            letter-spacing: -0.5px;
            color: $color-menu-text;
            font-weight: 900;

            a {
                display: block;
                position: relative;

                span {
                    transition: transform 0.6s $ease-custom;
                    display: block;
                }

                * {
                    pointer-events: none;
                }

                &::after {
                    content: "";
                    position: absolute;
                    inset: auto 0 2px auto;
                    height: vw(6);
                    transform: scaleX(0);
                    width: $menu-decoration-size;
                    background-color: $color-orange;
                    transition: all 0.6s $ease-custom;
                    transition-delay: 0s;
                    transform-origin: right;
                    pointer-events: none;

                    @include breakpoint(not-desktop) {
                        inset: auto 0 2px auto;
                        height: vw(6, phone);
                        width: $menu-decoration-size * 0.8;
                    }
                }

                &.is-active,
                &:hover {
                    // transform: translateX(-8px);

                    span {
                        display: block;
                        transform: translateX(-$menu-decoration-size - 8px);

                        @include breakpoint(not-desktop) {
                            transform: translateX(-$menu-decoration-size);
                        }
                    }

                    &::after {
                        transition-delay: 0.05s;
                        transform: scaleX(1);
                        margin-left: 8px;
                    }
                }
            }

            sup {
                @include font-size(10, 11);
                color: $color-menu-footnote;
                display: inline-block;
                letter-spacing: normal;
                font-weight: 400;
                margin-right: 5px;
                position: relative;
                top: -4px;
            }
        }
    }

    &__links {
        margin-top: vw(18);
        border-top: 1px solid $color-gray-light-3;
        //width: vw(200);
        height: vw(40);
        padding: vw(10) 0 0;
        list-style-type: none;

        @include breakpoint(not-desktop) {
            margin-top: vw(24, phone);
            padding-top: vw(15, phone);
            width: 100%;
        }

        li {
            @include font-size(16);
            font-weight: 700;
            float: left;
            margin-left: vw(30);
            transition: color 0.3s $ease-out;

            @include hover {
                color: $color-orange;
            }

            @include breakpoint(desktop) {
                &:first-of-type {
                    margin-left: 0;
                }
            }

            @include breakpoint(not-desktop) {
                @include font-size(18);
                float: initial;
                width: fit-content;
                margin-left: auto;
                margin-bottom: vw(10, phone);
            }
            
            @include breakpoint(tablet) {
                margin-bottom: vw(10, tablet);
            }
        }


    }
}