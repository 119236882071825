.list {
    position: absolute;
    top: 0;
    width: 100%;
    overflow-y: auto !important;
    height: var(--app-height);

    &:after {
        display: block;
        content: '';
        position: fixed;
        bottom: 0;
        width: 100%;
        height: vw(20);
        background: darken($color-gray-light, 1);
    }

    @include breakpoint(not-desktop) {
        position: absolute;
        left: 0;
    }

    &__container {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: -1;
        // background: darken($color-gray-light, 1);

        @include breakpoint(not-desktop) {
            margin-top: $header-height;
            padding: 0 18px;
            width: 100%;
        }
    }

    &__heading-wrapper {
        position: fixed;
        background: $color-white;
        top: 0;
        left: 0;
        height: vw(166);
        z-index: 1;
        box-shadow: vw(1) vw(1) vw(2) $color-gray;
        transition: height 0.5s $ease-out;

        &.is-wrapped {
            height: vw(118);
        }

        @include breakpoint(desktop) {
            @include flex();
            width: 100%;
            left: 0;
            right: unset;
        }

        @include breakpoint(not-desktop) {
            top: initial;
            bottom: 0;
            width: 100%;
            height: vw(170, phone);
            border-radius: 6px 6px 0 0;
            padding: vw(20, phone);

            .heading {
                max-width: unset;
            }

            &.is-wrapped {
                height: vw(110, phone);
            }

            h2 {
                width: fit-content;
                margin-right: vw(5, phone);
            }
        }

        @include breakpoint(tablet) {
            height: vw(190, tablet);

            &.is-wrapped {
                height: vw(145, tablet);
            }

            h2 {
                margin-right: vw(2, tablet);
            }
        }
    }

    &__wrapper {
        margin: vw(166) auto 0;
        padding: 34px;
        padding-bottom: 120px;
        width: 100%;
        position: relative;
        z-index: 0;
        transform: none !important;

        @include breakpoint(desktop) {
            padding: vw(20) 0 vw(50);
        }

        .is-grid & {
            display: grid;
            grid-template-columns: repeat(auto-fill, vw(400));
            justify-content: center;
            grid-gap: 20px;
        }

        @include breakpoint(not-desktop) {
            margin-top: vw(20, phone);
            grid-template-columns: repeat(auto-fill, 340px) !important;
            grid-gap: 12px !important;
            padding: 0 0 vw(250, phone) 0;
            width: 100%;
        }
        
        @include breakpoint(tablet) {
            grid-template-columns: repeat(auto-fill, vw(340, tablet)) !important;
            grid-gap: vw(15, tablet) !important;
            margin-top: vw(20, tablet);
        }
    }

    &__decoration {
        position: absolute;
        bottom: 0;
        right: 0;
        width: vw(6);
        height: 100%;
        background: $color-white;
        z-index: 1;

        &:after {
            @include full();
            content: '';
            display: block;
            background: $color-orange;
            transform-origin: bottom;
            transition: transform 0.7s $ease-out;
        }

        @include breakpoint(desktop) {
            width: vw(6);
        }

        @include breakpoint(not-desktop) {
            width: vw(6, phone);
        }
        
        @include breakpoint(tablet) {
            width: vw(6, tablet);
        }
    }

    &__item {
        position: relative;
        top: 0;
        width: 394px;
        height: 126px;
        background-color: $color-white;
        display: block;
        border-radius: 10px;
        // box-shadow: 0 4px 13px rgba(0, 0, 0, 0.15);
        padding: vw(17) vw(18) vw(15);
        overflow: hidden;
        color: $color-text;
        text-decoration: none;
        display: flex;
        height: 100%;
        transition: all 0.7s $ease-out;

        /* &.is-behind-header {
            pointer-events: none;
        } */

        a {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        @include breakpoint(desktop) {
            width: vw(400px);
            height: vw(140px);
            min-height: 126px;
            border-radius: 6px;
            // box-shadow: 0 vw(5px) vw(14px) rgba(0, 0, 0, 0.15);
            padding: vw(12) vw(16) vw(11) vw(168);
        }

        @include breakpoint(not-desktop) {
            width: vw(340, phone);
            height: vw(108, phone);
            padding: vw(13, phone) vw(11, phone) vw(13, phone) vw(97, phone);
        }

        @include breakpoint(tablet) {
            width: vw(340, tablet);
            height: vw(108, tablet);
            padding: vw(13, tablet) vw(11, tablet) vw(13, tablet) vw(97, tablet);
        }

        @include hover {
            background-color: $color-orange;
            color: $color-white;

            .list__decoration {
                &:after {
                    transform: scaleY(0);
                }
            }

            .list__image svg {
                transform: scale(1);
            }
        }
    }

    &__searchbar {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        z-index: 1;

        @include breakpoint(not-desktop) {
            bottom: 37px;
            width: vw(340, phone);
        }

        @include breakpoint(tablet) {
            width: vw(300, tablet);
        }
    }

    &__label {
        @include font-size(14);


        @include breakpoint(not-desktop) {
            @include font-size(12);
        }

        &--yield {
            margin-top: vw(5);

            @include breakpoint(not-desktop) {
                @include flex(column, flex-end, flex-end);
                position:absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        // padding: vw(5) vw(7);
    }

    &__name {
        @include font-size(14);

        @include breakpoint(not-desktop) {
            @include font-size(14);
        }
    }

    &__date {

        &-section {
            position: absolute;
            top: 0;
            right: 0;
            text-align: right;

            span {
                display: inline-block;
                position: relative;
                top: vw(-2);
                font-weight: 700;
                @include font-size(14, 1);
            }

            @include breakpoint(not-desktop) {
                bottom: 0;
            }
        }

        @include font-size(14, 20);
        font-family: $font-sans;
        white-space: nowrap;

        @include breakpoint(desktop) {
            font-weight: 700;
            @include font-size(14)
        }

        @include breakpoint(not-desktop) {
            @include font-size(14, 15);
            font-weight: 700;
        }
    }

    &__sort-list {
        position: absolute;
        bottom: 0;

        @include breakpoint(not-desktop) {
            left: vw(20, phone);
            right: 0;
        }
        
        @include breakpoint(tablet) {
            left: vw(20, tablet);
        }
    }

    &__volume {

        @include font-size(14);

        @include breakpoint(not-desktop) {
            margin-top: vw(2, phone);
            @include font-size(12, 13);
        }

        &-section {
            position: absolute;
            left: 0;
            bottom: -2px;
            text-align: left;
            padding-right: vw(5);

            @include breakpoint(not-desktop) {
                //left: 0;
                right: auto;
                text-align: left;
            }
        }

        span {
            font-weight: 700;
            @include font-size(24, 30);

            @include breakpoint(not-desktop) {
                @include font-size(20, 20);
            }
        }
    }

    &__image {
        @include flex();
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        height: calc(100% + 10px);
        width: vw(100);
        background-color: #F2F0F2;

        @include breakpoint(desktop) {
            height: calc(100% + vw(17));
            top: vw(-7px);
            left: vw(-8px);
            width: vw(160);
            //min-width: 127px;
        }

        @include breakpoint(not-desktop) {
            width: vw(85, phone);
        }
        
        @include breakpoint(tablet) {
            width: vw(85, tablet);
        }

        video {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }
    }

    &__footer {
        position: fixed;
        bottom: 33px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;

        .grid-button {
            margin-right: 20px;
        }
    }
}