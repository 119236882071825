.depositions {

    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;

    h3 {
        @include flex(row, flex-end, flex-end);
        color: $color-orange;
        font-weight: 800;
        padding-bottom: vw(2);
        white-space: nowrap;

        span.depositions__title {
            padding-bottom: vw(3);

            &:first-of-type {
                justify-self: flex-start;
                margin-left: 0;
                margin-right: auto;
            }
        }

        strong {
            @include font-size(20, 22);
            font-weight: 800;
            margin-right: vw(4);
        }

        @include breakpoint(not-desktop) {
            @include font-size(15, 19);
            padding-bottom: vw(4, phone);

            strong {
                @include font-size(15, 19);
                font-weight: 700;
                margin-right: vw(4, phone);
            }

            sup {
                margin-bottom: vw(5, phone);
            }
        }

        @include breakpoint(tablet) {
            @include font-size(20, 20);

            strong {
                @include font-size(20, 20);
                font-weight: 700;
                margin-right: vw(4, tablet);
            }
        }
    }


    &__top {
        width: 100%;
        @include flex(row, space-between, center);
        border-bottom: 1px solid $color-panel-text;
    }


    &__sort {
        @include flex(row, flex-start, center);
        white-space: nowrap;
        @include font-size(12);
        gap: vw(2);

        svg {
            width: vw(15);
            margin: vw(2) vw(2) 0;
            transition: $chevron-transition;
        }

        @include hover {
            svg {
                transform: $chevron-hover;
            }
        }
    }


    &__link {
        @include full;
        z-index: 1;
    }


    &__labels {
        @include flex(row, space-between, center);
        @include font-size(11, 1.5);
        color: $color-menu-footnote;
        border-bottom: 1px solid $color-panel-text;
        height: auto !important;
        margin-top: vw(6);
        padding-bottom: vw(2);
        background-color: $color-panel-bg;

        @include breakpoint(not-desktop) {
            @include font-size(10, 16);
            margin: 0;
            padding-bottom: vw(3, phone);
        }

        @include breakpoint(tablet) {
            @include font-size(14, 20);
        }
    }


    &__wrap {
        width: calc(100% + 20px);
        height: vh(180);
        overflow-x: hidden;
        overflow-y: auto;
        // border-bottom: 1px solid #B6B6B6;
        margin-left: -20px;
        background: $color-white;
        cursor: pointer;

        @include breakpoint(not-desktop) {
            height: 26vw;
        }

        @include breakpoint(hd) {
            height: vh(240);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 vw(6) rgba($color-white, 0);
            background-color: rgba($color-white, 0);

        }

        &::-webkit-scrollbar {
            width: vw(6);
            background-color: $color-gray-light-3;

            @include breakpoint(not-desktop) {
                width: vw(10, phone)
            }
        }

        &::-webkit-scrollbar-thumb {
            border-radius: vw(6);
            background-color: $color-orange;

            @include breakpoint(not-desktop) {
                width: vw(10, phone)
            }
        }

    }


    &__button {
        @include font-size(14);
        font-weight: 700;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-orange;
        padding: vw(5) vw(12) vw(7);
        border-radius: 6px;
        opacity: 0;
        transition: opacity 0.4s $ease-out;
        pointer-events: none;
        color: $color-white;

        @include breakpoint(not-desktop) {
            @include full();
            transform: none;
            pointer-events: all;
        }
    }


    &__item {
        position: relative;
        width: 100%;
        height: vw(44);
        display: grid;
        grid-template-columns: 60% 40%;
        border-top: 1px solid $color-gray-deposiotion;
        margin-left: 20px;
        padding-right: 22px;
        cursor: default;

        @include breakpoint(not-desktop) {
            height: vw(52, phone);
        }

        @include breakpoint(tablet) {
            height: vw(70, tablet);
        }

        &:first-of-type {
            border-top: none;
        }

        .col {

            &--left {
                @include flex(column, center, flex-start);
                gap: 2px;
            }

            &--right {
                padding-right: 4px;
                padding-top: 7px;
                text-align: right;
                @include flex(row, flex-end, flex-start);
                position: relative;

                @include breakpoint(not-desktop) {}
            }
        }

        .hours {
            @include font-size(15, 16);
            font-weight: 900;

            @include breakpoint(not-desktop) {
                @include font-size(12, 16);
            }

            @include breakpoint(tablet) {
                @include font-size(16, 20);
            }
        }

        .name {
            @include font-size(13, 16);
            letter-spacing: -0.07px;

            @include breakpoint(tablet) {
                @include font-size(16, 20);
            }
        }

        .sum {
            position: relative;
            top: vw(8);
            right: vw(3);
            @include font-size(16, 16);
            font-weight: 900;
            letter-spacing: -0.07px;
        }

        @include hover {
            &:before {
                transform: scaleY(1);
            }

            .depositions__button {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }


    &__dscr {
        @include font-size(14, 1);
        @include flex(row, space-between, center);
        font-weight: 400;

        @include breakpoint(not-desktop) {
            @include font-size(12, 16);
            margin: vw(18, phone) 0 vw(6, phone);
        }

        @include breakpoint(tablet) {
            @include font-size(16, 20);
            margin: vw(10, tablet) 0 vw(5, tablet);
        }

        button {
            text-decoration: underline;
            transition: opacity .3s;

            @include hover {
                opacity: .4;
            }
        }
    }

    &__clear {
        @extend .depositions__dscr;
        margin-top: 0;
        margin-bottom: vw(15);

        @include breakpoint(not-desktop) {
            margin-bottom: vw(15, phone);
        }

        @include breakpoint(tablet) {
            margin-bottom: vw(15, tablet);
        }
    }

    &__loader {
        @include full;
        @include flex(row, center, center);
        visibility: visible;
        pointer-events: none;
        z-index: -1;
        text-align: center;

        .hide-depositions & {
            visibility: visible;

            svg,
            svg circle {
                animation-play-state: running;
            }
        }
    }
}