
.livesearch {
    position: absolute;
    inset: 100% 0 auto;
    width: 100%;
    height: fit-content;
    color: $color-graphite;
    z-index: 3;
    visibility: hidden;


    &.is-visible {
        visibility: visible;

        ul {
            padding: vw(8) 0;
            background-color: $color-white;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border: 1px solid $color-graphite;
            border-top: none;
            max-height: vw(420);
            overflow-y: auto;
            z-index: -1;

            @include breakpoint(not-desktop) {
                padding: vw(8, phone);
                max-height: vw(130, phone);
            }

            @include breakpoint(tablet) {
                padding: vw(8, tablet);
            }
        }
    }

    ul {
        list-style-type: none;
    }

    ul,
    li,
    button {
        width: 100%;
        padding: 0;
    }

    li {
        @include font-size(16, 1);

        button {
            text-align: left;
            padding: vw(5) vw(10) vw(7);
            background-color: $color-white;
            transition: color 0.2s ease;


            @include hover {
                color: $color-orange;
            }
        }

        @include breakpoint(not-desktop) {
            margin-bottom: vw(5, phone);
        }

        @include breakpoint(tablet) {
            margin-bottom: 0;
            @include font-size(16);

            &:not(&:first-of-type) {
                margin-top: vw(8, tablet);
            }
        }
    }
}