.hourpicker {
    @include flex(row, flex-start, center);
    position: absolute;
    inset: auto vw(20) vw(20) vw(20);
    height: vw(24);
    background: $color-gray-light-3;
    border-radius: 6px;
    overflow: hidden;

    &__toggle {
        @include flex();
        height: 100%;
        width: vw(70);

        button {
            @include font-size(12);
            flex: 1;
            padding: vw(8);

            &.is-active {
                background: $color-orange;
                color: $color-white;
                font-weight: 900;
            }
        }
    }

    &__display {
        @include flex(row, space-between, center);
        height: 100%;
        width: vw(155);
        border-left: 1px solid $color-white;
        border-right: 1px solid $color-white;

        button {
            @include flex();
            width: vw(24);
            height: 100%;

            &:nth-of-type(1) {
                svg {
                    transform: rotate(90deg);
                }
            }

            &:nth-of-type(2) {
                svg {
                    transform: rotate(-90deg);
                }
            }

            svg {
                width: vw(10);
                stroke-width: 2px;
            }
        }
    }

    &__next {
        flex: 1;
        height: 100%;
        text-align: center;
        @include font-size(12);
        padding-bottom: 1px;
    }


    &__toggle>button,
    .hourpicker__next {
        @include hover {
            background: $color-orange;
            color: $color-white;
            font-weight: 700;
        }
    }


}