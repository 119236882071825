.legend {
    position: relative;
    width: vw(788);
    height: vw(82);
    background: $color-white;
    border-radius: 6px;
    pointer-events: all;
    padding: vw(12) vw(16);
    transition: opacity $time-fast $ease-custom;

    @include breakpoint(not-desktop) {
        padding: vw(12, phone);
        width: 100%;
        height: vw(58, phone);
    }
    
    @include breakpoint(tablet) {
        padding: vw(12, tablet);
        width: 100%;
        height: vw(85, tablet);
    }

    &__toggle {
        @include flex();
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: vw(-12);
        width: vw(36);
        height: vw(12);
        background: $color-white;
        border-radius: vw(4) vw(4) 0 0;
        border-bottom: 1px solid $color-gray-light-3;

        svg {
            path {
                stroke: $color-graphite;
            }
        }
    }

    &__upper-label {
        position: absolute;
        left: 0;
        top: vw(-9);
        transform: translateY(-100%);
        @include font-size(14, 14);
        font-weight: 700;
        text-transform: uppercase;
        user-select: none;
        
        span {
            text-transform: none;
        }
        
        @include breakpoint(not-desktop) {
            z-index: 1;
            @include font-size(11, 12);
            top: vw(-10, phone);
            width: 100%;
            
            .border-tooltip {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                
                svg {
                    width: vw(20, phone);
                    height: vw(20, phone);
                }
            }
        }
        
        @include breakpoint(tablet) {
            @include font-size(16, 16);
            top: vw(-15, tablet);

            .border-tooltip {
                svg {
                    width: vw(20, tablet);
                    height: vw(20, tablet);
                }
            }
        }
    }

    &__heading {
        @include font-size(12, 12);
        font-weight: 400;
        margin-bottom: vw(9);
        position: relative;
        display: inline-block;
    }

}
