.hamburger {
    display: block;
    width: $hamburger-width;
    position: relative;
    transition: opacity .2s $ease-custom;

    .is-menu-open & {
        /* background-color: rgba($color-bg, 0);
        box-shadow: 0 0 6px 4px rgba($color-bg, 0);
        transition-delay: 0.1s; */
        opacity: 0;
    }

    @include breakpoint(not-desktop) {
        margin-top: 10px;
    }

    svg {
        width: 100%;

        path {
            transform: scaleY(2);
            stroke-dasharray: 27px;
            transition: stroke-dashoffset 0.5s $ease-out 0s;
            &:nth-of-type(2) {
                transform: scaleY(2) translateY(-25%);
            }

            &:nth-of-type(3) {
                transform: scaleY(2) translateY(-50%);
            }
        }
    }

    .body:not(.is-menu-open) & {


        &:hover {
            svg {
                path {
                    &:nth-of-type(1) {
                        stroke-dashoffset: 10px;
                    }

                    &:nth-of-type(2) {
                        stroke-dashoffset: 20px;
                    }

                    &:nth-of-type(3) {
                        stroke-dashoffset: 10px;
                    }
                }
            }
        }
    }


    body.is-menu-open & {

        /* svg {
            path {
                transition-delay: 0s;

                &:nth-of-type(1) {
                    stroke-dashoffset: 27px;
                }

                &:nth-of-type(2) {
                    stroke-dashoffset: 27px;
                }

                &:nth-of-type(3) {
                    stroke-dashoffset: 27px;
                }
            }
        } */
    }
}
