.arrow {
    width: 16px;
    transition: transform 0.5s $ease-out;
    display: block;
    pointer-events: none;
    margin: -5px 0 0 3px;
    opacity: 0;

    svg {
        width: 100%;
        transition: transform 0.3s $ease-out, opacity 0.3s $ease-out;

        path {
            stroke: $color-orange;
        }
    }

    @include breakpoint(not-desktop) {
        margin: vw(3, phone) vw(5, phone) vw(2, phone) vw(6, phone);
        
        svg {
            path {
                stroke-width: 1.5px;
            }
        }
    }
    
    
    @include breakpoint(tablet) {
        margin: vw(3, tablet) vw(5, tablet) vw(2, tablet) vw(6, tablet);
    }

    @include hover {
        svg {
            transform: rotate(90deg);
        }
    }

    body.is-menu-open & {
        pointer-events: auto;
        opacity: 1;

        /* path {
            transition-delay: 0.2s;
            stroke-dashoffset: 0;
        } */
    }
}