.sort-list {
    @include flex(row, space-between, center);
    @include font-size(18);
    position: relative;
    font-weight: 700;
    width: vw(400);
    height: vw(36);
    background: $color-white;
    border-radius: 6px;
    border: 1px solid $color-graphite;
    color: $color-text;
    cursor: pointer;
    padding: 0 vw(12);
    margin-top: vw(12);
    
    h2 {
        position: relative;
        top: vw(-1);
        display: inline-block;
        @include font-size(18);
        pointer-events: none;
    }
    
    span {
        font-weight: 400;
    }
    
    svg {
        pointer-events: none;
        margin: auto 0;
        width: vw(15);
        transition: $chevron-transition;
        
        @include breakpoint(not-desktop) {
            width: vw(25, phone);
        }
    }
    
    @include breakpoint(not-desktop) {
        width: 100%;
        height: vw(36, phone);
        border-radius: 6px;
        padding: 0 vw(12, phone) vw(2, phone);
        z-index: 2;
        
        &:after {
            content: '';
            position: absolute;
            inset: -1px;
            border-radius: inherit;
            background: inherit;
            border: inherit;
            z-index: -1;
        }
    }
    
    @include breakpoint(tablet) {
        padding: 0  0 vw(2, tablet) vw(12, tablet);
        height: vw(36, tablet);
    }

    @include hover {
        svg {
            transform: $chevron-hover;
        }
    }

}