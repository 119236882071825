.panel {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    height: 100vh;
    // overflow: hidden;
    padding: vw(20) vw(24) vw(24);
    background-color: $color-panel-bg;
    // border-left: 1px solid rgba($color-graphite, 0.2);

    @include breakpoint(not-desktop) {
        margin-top: $header-height;
        max-height: calc(100vh - $header-height);
        overflow: hidden;
    }

    @include breakpoint(tablet) {
        padding: vw(40, tablet) vw(26, tablet);
    }

    @include breakpoint(desktop) {
        min-height: vw(620);
    }

    &__title {
        @include font-size(16, 1);
        font-weight: 800;
        margin-bottom: vw(15);
    }

    &__subtitle {
        @include font-size(14, 1);
        @include flex(row, flex-start, center);
        gap: vw(6);
        font-weight: 400;
        margin-bottom: vw(8);

        @include breakpoint(not-desktop) {
            gap: vw(7, phone);
            margin-bottom: vw(9, phone);
            @include font-size(14, 20);
        }

        @include breakpoint(tablet) {
            margin-bottom: vw(12, tablet);
            @include font-size(16, 20);
        }

        span {
            margin-top: vw(1);
            @include font-size(12, 1.6);
        }

        & + span {
            @include font-size(10, 15);
            font-weight: 700;
            color: $color-white;
            display: inline-block;
            position: absolute;
            top: vw(0.5);
            left: vw(4.7);

            @supports (-webkit-touch-callout: none) {
                top: vw(2, phone);
                left: vw(7, phone);
            }

            @include breakpoint(tablet) {
                @include font-size(18, 22);
                top: vw(0, tablet);
                left: vw(8, tablet);
            }
        }

        .panel__group--filters & {
                & + span {
                    top: vw(1);
                }

            body.is-conus &, body.is-states &, body:not(.has-current-coord) & {
                color: $color-gray-light-2;
                pointer-events: none;

                svg {
                    circle {
                        fill: $color-gray-light-2;
                    }
                }
            }
        }


        svg {
            width: vw(15);

            @include breakpoint(not-desktop) {
                width: vw(20, phone);

                @supports (-webkit-touch-callout: none) {
                    width: vw(21, phone);
                }
            }

            circle {
                &:nth-of-type(2) {
                    display: none;
                }
            }

            @include breakpoint(tablet) {
                width: vw(26, tablet);
            }
        }
    }


    &__filters {
        &-shown {
            display: inline;

            body.is-depositions-expanded & {
                display: none;
            }
        }

        &-hidden {
            display: none;

            body.is-depositions-expanded & {
                display: inline;
            }
        }
    }


    &__group {
        width: 100%;
        position: relative;

        & + .panel__group {
            margin-top: vw(10);

            @include breakpoint(not-desktop) {
                margin-top: vw(12, phone);
            }
        }


        @each $identifier in $panel-elements {

            &--#{$identifier} {
                @include z-index($identifier, $panel-elements);
            }
        }

        &--filters,
        &--search {
            .is-init-aggregtaion & {
                > * {
                    opacity: .3;
                    pointer-events: none;
                }
            }
        }

        &--aggregation,
        &--filters,
        &--search {
            @include breakpoint(not-desktop) {
                transition: opacity 0.3s $ease-custom;

                body.is-depositions-expanded & {
                    opacity: 0 !important;
                }
            }
        }

        &--filters {
            body:not(.has-current-coord) & {
                > * {
                    // opacity: 0.3;
                    pointer-events: none;
                }
            }

            body.is-calendar-open & {
                z-index: 5;
            }
        }

        &--search {
            .is-conus & {
                > * {
                    // opacity: 0.3;
                    pointer-events: none;
                }

                .panel__subtitle {

                    color: $color-gray-light-2;

                    svg {
                        circle {
                            fill: $color-gray-light-2;
                        }
                    }
                }
            }
        }

        &--statistics {
            z-index: 2;
            margin: vw(10) 0 vw(10) !important;

            body.is-calendar-open & {
                z-index: 0;
            }
        }

        &--depositions {
            position: relative;
            margin-top: 0 !important;
            flex: 1;

            .depositions > *:not(.depositions__loader),
            > p {
                transition: 0.2s opacity;
            }
            .is-init-aggregtaion & {
                .depositions > *:not(.depositions__loader),
                > p {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            body:not(.has-current-coord) & {
                .depositions > *:not(.depositions__loader),
                > p {
                    opacity: 0;
                }
            }

            body.hide-depositions & {
                .depositions > *:not(.depositions__loader),
                > p {
                    opacity: 0;
                }
            }

            @include breakpoint(not-desktop) {
                background-color: $color-panel-bg;
            }
        }
    }

    &__hide {
        @extend .depositions__dscr;
        position: fixed;
        top: calc($header-height - vw(20, phone));
        right: vw(18, phone);
        z-index: 1;
        transition: opacity 0.5s $ease-out;
        pointer-events: all;

        .panel & {
            position: static;
            opacity: 0;
            pointer-events: none;
            margin: 0;

            body.has-current-coord & {
                pointer-events: auto;
                opacity: 1;
            }
        }

        @include breakpoint(tablet) {
            top: $header-height;
        }
    }

    &__search {
        position: relative;
    }


    &__calendar {
        padding-top: 0;
        margin-top: 0;
        width: 100%;
        z-index: 0;
        position: absolute;
        bottom: vw(12);

        & + * {
            margin-top: vw(8);

            @include breakpoint(not-desktop) {
                margin-top: vw(11, phone);
            }
        }

        @include breakpoint(tablet) {
            left: 50%;
            transform: translateX(-43%);
        }
    }
}
