.statistics {
    height: vw(130);


    &__top {
        @include flex(row, flex-start, center);
        gap: vw(15);
        padding-bottom: vw(6);
        border-bottom: 1px solid $color-panel-text;
        margin-bottom: vw(10);

        button {
            position: relative;
            font-weight: 700;
            @include font-size(14);

            &::after {
                content: "";
                position: absolute;
                bottom: vw(-7);
                left: 0;
                width: 100%;
                height: 2px;
                background: $color-orange;
                opacity: 0;
                transition: opacity $time-fast $easing;
            }

            &.is-active {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}