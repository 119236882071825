.stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
    padding-bottom: vw(40);

    &__item {
        @include flex(column, center, center);

        &:nth-of-type(2n + 0) {
            border-left: 1px solid $color-panel-text;
        }

        &:nth-of-type(1n + 3) {
            border-top: 1px solid $color-panel-text;
        }
    }
}