$calendar-hd-bp: 1700px;

.calendar {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    min-width: 100px;
    height: fit-content;
    background-color: transparent;
    color: $color-calendar-text;
    border-radius:  0 0 6px 6px;
    position: absolute;
    inset: 100% 0 auto;
    height: vw(325);
    max-height: 390px;

    @include breakpoint(not-desktop) {
        border-radius: vw(8, phone);
    }

    @include breakpoint(tablet) {
        width: vw(600, tablet);
    }

    input,
    textarea {
        outline: none;
        border: none;
    }


    &__input {
        width: 70%;

        input {
            @extend .calendar__placeholder;
            width: 100%;
            background: transparent;
        }
    }


    &__wrapper {
        position: relative;

        &.is-advanced {

            .calendar {
                overflow: hidden;
                position: absolute;
                inset: 100% 0 auto;
                background-color: $color-white;
                border: 1px solid $color-graphite;
                padding: vw(20) vw(18) vw(20);
                margin-top: vw(8);

                @media (min-width: $calendar-hd-bp) {
                    padding: vw(20) vw(18) vw(20);
                    margin-top: vw(6);
                }

                @include breakpoint(not-desktop) {
                    padding: vw(18, phone) vw(15, phone) vw(15, phone);
                    inset: unset;
                    top: vw(-25, phone);
                }
            }

            .calendar__close {
                display: block;

                @include breakpoint(not-desktop) {
                    display: none;
                }
            }

            .calendar__interval {
                margin-top: vw(15);

                @media (min-width: $calendar-hd-bp) {
                    margin-top: vw(9);
                }

                @include breakpoint(not-desktop) {
                    margin-top: 0;
                    margin-bottom: vw(13, phone);
                }
            }
        }
    }


    &__calendar {
        &.is-hours-open {
            .react-calendar {
                visibility: hidden;
            }
        }

        @include breakpoint(tablet) {
            width: 100%;
        }
    }


    &__dscr {
        @include font-size(12, 1);
        @include flex(row, flex-end, center);
        font-weight: 400;
        padding-top: 0;
        margin-bottom: vw(4);

        button {
            @include font-size(12, 15);
            padding: 0 vw(6);
            color: $color-graphite;
            background-color: #EFEFEF;
            border-radius: 8px;
            height: vw(20);
            transition: color $time $ease-custom;

            @include hover {
                color: $color-orange;
            }
        }
    }


    &__close {
        width: vw(14);
        align-self: flex-end;
        margin-left: auto;
        margin-right: 0;
        display: none;

        svg {
            display: block;
            width: 100%;
            transition: transform 0.5s $ease-custom;
            transform-origin: center;
        }

        @include breakpoint(desktop) {

            @media (min-width: $calendar-hd-bp) {
                width: vw(10);
            }
        }

        @include hover {

            svg {
                transform: rotate(180deg);
            }
        }
    }


    &__interval {
        @include flex(row, flex-start, center);
        width: 100%;
        column-gap: vw(7);
        margin-top: vw(4);

        & + .calendar__calendar {
            margin-top: vw(13);
        }

        @media (min-width: $calendar-hd-bp) {
            column-gap: vw(10);

            & + .calendar__calendar {
                margin-top: vw(9);
            }
        }
    }


    &__sep {
        background-color: #2B2B2B;
        width: vw(8);
        height: 1px;

        @include breakpoint(not-desktop) {
            width: vw(11, phone);
            margin: 0 vw(2, phone);
        }
    }


    &__item {
        flex: 1;
        background-color: $color-calendar-inner;
        color: $color-calendar-text;
        border-radius: 8px;
        height: vw(45);
        overflow: hidden;
        display: flex;

        @media (min-width: $calendar-hd-bp) {
            height: vw(35);
        }

        @include breakpoint(not-desktop) {
            height: vw(34, phone);
        }

        @include breakpoint(tablet) {
            width: vw(220, tablet);
        }

        &.is-active {

            .is-advanced & {
                background-color: $color-orange;
                color: $color-gray-medium;

                /* .calendar__placeholder {
                    color: $color-gray-medium;
                }*/
                
                input {
                    color: $color-gray-medium;

                    &::placeholder {
                        color: $color-gray-medium;
                        opacity: 0.4;
                    }
                }

                svg {

                    path {
                        stroke: $color-gray-medium;
                    }

                    line {
                        stroke: $color-gray-medium;
                    }
                }
            }
        }
    }


    &__placeholder {
        @include font-size(13, 2);
        letter-spacing: 0.2px;
        font-weight: 800;
        width: 100%;
        padding: 0 vw(12);
        text-transform: uppercase;
        flex: 1;
        white-space: nowrap;
        color: $color-calendar-disabled;
        text-align: left;
        color: $color-calendar-text;

        &.is-selected {
            color: $color-calendar-text;
        }

        @media (min-width: $calendar-hd-bp) {
            @include font-size(11, 1);
        }

        @include breakpoint(not-desktop) {
            padding: 0 vw(12, phone) vw(2, phone);
        }

        @include breakpoint(tablet) {
            @include font-size(18, 20);
        }
    }


    &__icon {
        @include flex(row, center, center);
        width: vw(45);
        min-width: vw(45);
        max-width: vw(45);
        height: 100%;
        border-left: 1px solid #CECECE;

        svg {
            width: vw(16);
        }

        @include breakpoint(not-desktop) {
            width: vw(37, phone);
            max-width: unset;

            svg {
                width: vw(14, phone);
            }
        }
    }


    &__button {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
    }
}