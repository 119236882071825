.loader {
    @include flex(column, flex-end, flex-start);
    @include z-index(loader);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-bg;
    padding: vw(33);

    @include breakpoint(not-desktop) {
        height: 100svh;
        width: 100vw;
    }

    &__progress {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: vw(6);

        @include breakpoint(not-desktop) {
            height: vw(6, phone);
        }
        
        &-bar {
            height: 100%;
            width: 100%;
            background: $color-orange;
            transform-origin: left;
            transform: scaleX(0);
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-40%);
        gap: vw(70);
        padding-left: vw(30);
        @include flex(row, flex-start, center);
        width: 33.33vw;
        
        @include breakpoint(not-desktop) {
            @include flex(row, space-between, center);
            @include full();
            top: 0;
            transform: none;
        }
    }

    &__logotype {
        width: vw(140);
        height: auto;
        
        @include breakpoint(not-desktop) {
            position: relative;
            right: unset;
            left: vw(10, phone);

            svg {
                max-width: unset;
                width: vw(153, phone);
                height: auto;
            }
        }
    }

    &__counter {
        @include font-size(22, 28);
        vertical-align: bottom;
        font-weight: 800;
        color: $color-graphite;

        @include breakpoint(not-desktop) {
            @include font-size(20, 25);
            position: relative;
            left: vw(-15, phone);
            top: vw(10, phone);
        }
    }

    &__video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
        opacity: 0;
        animation: fadeVideo 0.4s linear 0.2s;
        animation-fill-mode: forwards;

        @include breakpoint(phone) {
            animation: fadeVideo 1s linear 1s;
        }

        video {
            filter: sepia(20%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
            object-fit: cover;

            @include breakpoint(not-desktop) {
                height: 100vh;
                object-position: 29% 50%;
            }
        }

        @include breakpoint(not-desktop) {
            width: 150%;
        }
    }
}
