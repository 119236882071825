.range {
    height: vw(36);
    border: none;

    @include breakpoint(not-desktop) {
        height: vw(54, phone);
    }

    @include breakpoint(tablet) {
        height: vw(80, tablet);
    }

    &--depositions {
        margin: 20px 0;
    }

    &--grid {
        position: relative;
        border-radius: vw(2);
        height: vw(20);
        width: 100%;
        background: linear-gradient(90deg, #D9D9D9 0%, #D9D9D9 8.68%, #BEBCBF 16.72%, #F4F35A 24.98%, #F0B44D 33.46%, #DF8159 41.83%, #D56E65 50.08%, #C55C70 58.34%, #B6497D 66.71%, #A73A89 74.97%, #9B2F94 83.23%, #7316A1 91.82%, #290891 100%);

        .noUi-horizontal {
            height: vw(20);
            display: none; // Should legend filtering feature be blocked on mobile?
        }

        @include breakpoint(not-desktop) {
            height: vw(20, phone);
            border-radius: 2px;
            pointer-events: none; // Should legend filtering feature be blocked on mobile?
        }
        .noUi-connect,
        .noUi-target,
        .noUi-base {background: transparent;}

        .noUi-connects,
        .noUi-target,
        .noUi-base {border-radius: vw(2);}

        .noUi-target {border: none;}

        .noUi-handle {
            top: 0;
            // right: vw(-10);
            height: vw(20);
            width: vw(10);
            background: none;
            border: none;
            box-shadow: none;
            cursor: grab;

            @include breakpoint(not-desktop) {
                top: vw(-3, phone);
                right: vw(-9, phone);
                width: vw(22, phone);
                height: vw(60, phone);
            }

            @include breakpoint(tablet) {
                width: vw(35, tablet);
                height: vw(93, tablet);
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                background-color: $color-white;
                z-index: 0;
            }

            &::after {
                content: "";
                position: absolute;
                top: calc(50% - vw(4.6));
                left: vw(4);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: vw(5) 0 vw(5) vw(4);
                border-color: transparent transparent transparent $color-white;
                background: none;
                z-index: 0;
            }

            .noUi-touch-area {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40%;
                    height: 100%;
                    background-color: $color-graphite;
                    z-index: 1;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: calc(50% - vw(3));
                    left: vw(4);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: vw(3.5) 0 vw(3.5) vw(3);
                    border-color: transparent transparent transparent $color-graphite;
                    background: none;
                    z-index: 1;
                }
            }

            &.noUi-handle-lower {
                right: vw(-10);

                @include breakpoint(not-desktop) {
                    right: vw(-18, phone);
                }

                @include breakpoint(tablet) {
                    right: vw(-22, tablet);
                }

                &::after {
                    left: vw(4);
                    transform: scaleX(-1);
                    border-width: vw(5) vw(4) vw(5) 0;
                    border-color: transparent $color-white transparent transparent;

                    @include breakpoint(not-desktop) {
                        left: vw(10, phone);
                        border-width: vw(6.5, phone) vw(5, phone) vw(6.5, phone) 0;
                    }

                    @include breakpoint(tablet) {
                        right: vw(-22, tablet);
                    }
                }

                &::before {
                    left: 0;
                    // width: 28% !important;
                }


                .noUi-touch-area {
                    &::after {
                        left: vw(4);
                        transform: scaleX(-1);
                        border-width: vw(3.5) vw(3) vw(3.5) 0;
                        border-color: transparent currentColor transparent transparent;

                        @include breakpoint(not-desktop) {
                            left: vw(10, phone);
                            border-width: vw(6.5, phone) vw(5, phone) vw(6.5, phone) 0;
                        }

                        @include breakpoint(tablet) {
                            right: vw(-22, tablet);
                        }
                    }

                    &::before {
                        left: 0;
                    }
                }

            }

            &.noUi-handle-upper {
                right: 0;

                &::before {
                    right: 0;
                    left: unset;
                }
                &::after {
                    right: vw(4);
                    left: unset;
                    transform: scaleX(-1);
                }

                .noUi-touch-area {
                    right: 0;

                    &::before {
                        right: 0;
                        left: unset;
                    }
                    &::after {
                        right: vw(4);
                        left: unset;
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }

    &__labels {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        user-select: none;
    }

    &__label {
        position: relative;
        border-right: solid 1px rgba(241, 241, 241, 1);

        &:last-of-type {
            border-right: none;
        }

        span {
            position: absolute;
            @include font-size(9, 9);
            letter-spacing: 0.2px;
            width: 100%;
            text-align: right;
            bottom: vw(-8);
            transform: translateY(100%);
        }

        @include breakpoint(not-desktop) {
            span {
                @include font-size(11);
                display: none;
            }

            &:last-of-type, &:first-of-type {
                span {
                    display: block;
                    white-space: nowrap;
                }
            }

            &:last-of-type {
                span {
                    left: vw(-25, phone);
                }
            }
        }
        
        @include breakpoint(tablet) {
            
            span {
                @include font-size(14);
            }

            &:last-of-type {
                span {
                    left: vw(-10, tablet);
                }
            }
        }
    }

    .noUi-connects {
        border-radius: 0;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: repeating-linear-gradient(
                to left,
                $color-gray-medium 0px,
                $color-gray-medium 1px,
                transparent 1px,
                transparent 6px
            );
        }
    }

    .noUi-connect {
        background: rgba($color-white, 0.7);
        z-index: 2;
    }

    .noUi-handle {
        top: 0;
        // right: vw(-10);
        height: vw(36);
        width: vw(20);
        background: none;
        border: none;
        box-shadow: none;
        cursor: grab;

        @include breakpoint(not-desktop) {
            top: vw(-3, phone);
            right: vw(-9, phone);
            width: vw(22, phone);
            height: vw(60, phone);
        }

        @include breakpoint(tablet) {
            width: vw(35, tablet);
            height: vw(93, tablet);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: vw(5);
            width: 30%;
            height: 100%;
            background-color: $color-graphite;

            @include breakpoint(not-desktop) {
                left: vw(5, phone);
            }

            @include breakpoint(tablet) {
                left: vw(9, tablet);
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: calc(50% - vw(3));
            left: vw(10);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: vw(4.5) 0 vw(4.5) vw(4.5);
            border-color: transparent transparent transparent currentColor;
            background: none;

            @include breakpoint(not-desktop) {
                top: calc(50% - vw(5, phone));
                left: vw(10, phone);
                border-width: vw(6.5, phone) 0 vw(6.5, phone) vw(5, phone);
            }
        }

        &.noUi-handle-lower {
            // right: vw(-8);

            @include breakpoint(not-desktop) {
                right: vw(-18, phone);
            }

            @include breakpoint(tablet) {
                right: vw(-22, tablet);
            }

            &::after {
                left: vw(11);
                transform: scaleX(-1);
                border-width: vw(4.5) vw(4.5) vw(4.5) 0;
                border-color: transparent currentColor transparent transparent;

                @include breakpoint(not-desktop) {
                    left: vw(10, phone);
                    border-width: vw(6.5, phone) vw(5, phone) vw(6.5, phone) 0;
                }

                @include breakpoint(tablet) {
                    right: vw(-22, tablet);
                }
            }

            &::before {
                left: vw(5);

                @include breakpoint(tablet) {
                    left: vw(7, tablet);
                }
            }

        }

        &.noUi-handle-upper {
            right: 0;

            &::before {
                right: 0;
                left: unset;
            }
            &::after {
                right: vw(6);
                left: unset;
                transform: scaleX(-1);
            }
        }

        .is-init-animation & {
            // animation: moveArrLeft 2.6s infinite;

            &.noUi-handle-lower {
                // animation: moveArrRight 2.6s infinite;
            }
        }
    }

    .noUi-marker-horizontal.noUi-marker-sub { display: none;}

    .noUi-value-sub {
        color: $color-text;
        @include font-size(9);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: vw(-6);
            left: calc(50% - 1px);
            height: vw(6);
            width: 1px;
            background: currentColor;

            @include breakpoint(not-desktop) {
                top: vw(-4, phone);
                height: vw(6, phone);
            }
        }

        @include breakpoint(not-desktop) {
            font-size: vw(9, phone);

            &:nth-of-type(2) {
                padding-left: vw(15, phone);

                &:before {
                    left: calc(50% + 1px);
                }
            }

            &:last-of-type {
                &:before {
                    left: calc(50% - 2px);
                }
            }
        }

        @include breakpoint(tablet) {
            @include font-size(14, 25);
        }
    }
    .noUi-pips-horizontal {
        top: 65%;

        @include breakpoint(hd) {
            top: 85%;
        }

        @include breakpoint(not-desktop) {
            top: 78%;
        }

        > div {
            &:nth-of-type(4n + 4) {
                opacity: 0;
            }
        }
    }


    .is-init-range & {
        .noUi-connect,
        .noUi-origin {
        -webkit-transition: transform 1.45s $ease-custom; // Change this to the desired duration
            transition: transform 1.45s $ease-custom;
        }
    }
}
