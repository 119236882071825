.mapgrid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $color-bg;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    
    @include breakpoint(not-desktop) {
        grid-template-columns: repeat(3, 1fr);

        body.is-loaded & {
            display: none;
        }
    }

    div {
        border: 1px solid $color-gray-medium;
        opacity: 0.3;
    }

}