$mobile-modal-transition: opacity 0.3s $ease-out;

.section {
    @include flex(column, flex-start, center);
    width: 100%;
    // max-height: 100vh;
    position: relative;
    z-index: 1;

    body.is-map-usable & {
        pointer-events: none;
    }

    &__heading {
        @include breakpoint(phone) {
            position: absolute;
            left: 0;
            top: $header-height;
            min-width: 300px;
        }
    }

    &.is-full {
        height: 100vh;

        @include breakpoint(not-desktop) {
            height: 100svh;
        }
    }

    &.is-center {
        @include breakpoint(desktop) {
            justify-content: center;
            align-items: center;
        }
    }

    &.is-bottom {
        justify-content: flex-end;
        align-items: center;
    }

    &__flex {
        @include flex(row, center, center);
        width: 100%;

        @include breakpoint(phone) {
            @include flex(column, center, center);
            width: 100%;
        }
    }

    &__wrapper {
        height: 100%;
        padding-top: vw(100);
        padding-bottom: vw(150);

        @include flex(column, center, flex-start);

        // @include breakpoint(desktop) {
        //     margin-top: vw(300);
        // }

        video {position: relative;}

        @include breakpoint(not-desktop) {
            height: 100vh;
            padding-top: vw(100, phone);
            @include flex(column, center, flex-start);

            video {
                width: vw(280, phone);
                height: vw(250, phone);
                transition: $mobile-modal-transition;
            }
            
            &.is-modal-open {
                video {
                    opacity: 0;
                }
            }
        }
    }
    
    &__modal {
        @include breakpoint(desktop) {
            display: none;
        }

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: all;
    }
    
    &__legend {
        
        @include breakpoint(desktop) {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 0 vw(28) vw(43)
        }
        
        @include breakpoint(not-desktop) {
            width: calc(100% - 36px);
            position: absolute;
            bottom: vw(30, phone);
            left: 50%;
            transform: translateX(-50%);
            padding-left: 4px;

            .button {
                margin-bottom: vw(60, phone);
                pointer-events: all;

                &:before, &:after {
                    display: none;
                }
            }
        }
        
        @include breakpoint(tablet) {
            padding-left: 0;

            .button {
                margin-bottom: vw(90, tablet);
            }
        }
    }
    
    &__button {
        width: 100%;
        transition: $mobile-modal-transition;
        transform: translateZ(0);
        
        .section__wrapper.is-modal-open & {
            pointer-events: none;
            opacity: 0;
        }
    }
    
    &__inner {
        max-width: vw(507);
        margin: 0 auto vw(10);
        
        @include breakpoint(not-desktop) {
            max-width: unset;
            width: vw(280, phone);
            transition: $mobile-modal-transition;
            
            .section__wrapper.is-modal-open & {
                opacity: 0;
            }
        }

        @include breakpoint(tablet) {
            width: vw(500, tablet);
        }
    }

    &__logo {
        position: absolute;
        bottom: 40px;
        left: 40px;

        @include breakpoint(not-desktop) {
            bottom: 20px;
            left: 50%;
            margin-left: -61px;
        }

        @include breakpoint(phone) {
            width: 74px;
            height: 18px;
            margin-left: -37px;
        }
    }

    &__panel {
        position: absolute;
        height: 100%;
        width: vw($panel);
        right: 0;
        bottom: 0;
        pointer-events: all;
        @include z-index(panel);
        transform: translateZ(0);

        @include breakpoint(desktop) {
            overflow-y: auto;
        }

        @include breakpoint(not-desktop) {
            position: fixed;
            transform: translateZ(0) translateX(100%);
            transition: transform 0.8s $ease-out;
            width: 100%;

            body.is-filters-visible & {
                transform: translateZ(0) translateX(0);
            }
        }
    }

    &__tab {

        @include breakpoint(desktop) {
            display: none;
        }

        position: absolute;
        left: -1px;
        top: 0;
        height: 100%;
        width: vw(29, phone);
        transform: translateX(-100%);
        background-color: $color-map-label;
        border-radius: vw(7, phone) 0 0 vw(7, phone);
        pointer-events: all;

        span {
            @include flex(row, center, center);
            @include font-size(13, 16);
            width: auto;
            white-space: nowrap;
            margin: 0 vw(1, phone) vw(14, phone) 0;
            transform: rotate(-90deg);

            @include breakpoint(tablet) {
                margin-bottom: vw(14, tablet);
            }
        }

        svg {
            position: absolute;
            bottom: vw(9, phone);
            left: vw(10, phone);
            width: vw(11, phone);
            height: vw(11, phone);
            transform: rotate(90deg);
            transition: transform 0.4s $ease-custom;

            @include breakpoint(tablet) {
                width: vw(13, tablet);
                height: vw(13, tablet);
                left: vw(15, tablet);
            }

            body.is-extra-visible & {
                transform: rotate(-90deg);
            }
        }

        .extra__outer & {
            height: vw(164, phone);

            @include breakpoint(tablet) {
                width: vw(38, tablet);
                height: vw(200, tablet);
            }
        }
    }

    &__copy {
        margin-bottom: 23px;
    }
}
