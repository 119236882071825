.map {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    position: absolute;
    inset: 0;
    transition: transform 1.5s $ease-custom;

    @include breakpoint(not-desktop) {
        height: 56vh;
        width: 100%;
        margin-top: $header-height;
        pointer-events: none;
        
        &.is-single {
            margin-top: $header-height * 0.7;
            height: 45vh;
        }
    }
    
    @include breakpoint(tablet) {
        height: 70vh;
    }

    @include breakpoint(desktop) {
        &.is-single {
            transform: translateY(vw(60));
        }
    }

    body.is-map-extended & {
        @include breakpoint(desktop) {
            transform: translateX(-15%);
        }
    }

    
    body.is-menu-open & {
        transform: translateY(var(--menu-height));
        pointer-events: none;
    }

    @include breakpoint(desktop) {
        body.is-menu-open.is-map-extended & {
            transform: translate(-15%, var(--menu-height));
        }
    }

    &__frame {
        @include flex(row, space-between, flex-end);
        position: absolute;
        inset: vw(140) vw(270) vw(140) vw(270);
        padding: vw(5);
        border: 1px solid $color-orange;
        pointer-events: none;
        
        @include breakpoint(not-desktop) {
            inset: vw(60, phone) vw(15, phone);
        }

        .map__border {
            position: absolute;
            top: vw(10, phone);
            right: vw(6, phone);
        }
    }

    &__loader {
        @include flex();
        @include full();
        background: $color-gray-light-3;
        z-index: 2;
        display: none;

        @include breakpoint(not-desktop) {
            position: fixed;
            top: 0;
            left: 0;
            height: 85svh;
            width: 100vw;
        }

        svg {
            animation-play-state: running;
            
            circle {
                animation-play-state: running;
            }
        }
    
        body.is-plumes-loading & {
            display: flex;
        }
    }

    &__model {
        width: fit-content;
        height: fit-content;
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: all;

        .border-tooltip {
            @include font-size(12);
            letter-spacing: vw(1.3);
            position: relative;
            border-radius: 0;
            border: none;
            background: $color-orange;
            color: $color-gray-light-3;
            font-weight: 700;
            width: vw(130);
            height: vw(25);

            .border-tooltip__popup {
                font-weight: 400;
                letter-spacing: initial;
                color: $color-text;
            }

            svg {
                width: vw(13);

                circle {
                    fill: $color-gray-light-3;
                }

                path {
                    stroke: $color-orange;
                }
            }
        }

        @include breakpoint(not-desktop) {
            text-transform: uppercase;
            color: $color-white;
            font-weight: 700;
            background: $color-orange;
            padding: vw(3, phone) vw(8, phone);
            @include font-size(12);
            letter-spacing: vw(1, phone);
        }
    }

    &__days {
        @include flex(row, flex-start, center);
        @include font-size(12);
        gap: vw(3);
        text-transform: uppercase;
        color: $color-orange;

        @include breakpoint(not-desktop) {
            position: absolute;
            left: 0;
            bottom: vw(-2, phone);
            transform: translateY(100%);
        }

        svg {
            width: vw(15);
            height: auto;
            position: relative;
            top: vw(1);

            path {
                stroke-width: 0px;
                fill: $color-orange;
            }
        }
    }

    &__wrap {
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.6s $ease-out;

        &.is-visible {
            opacity: 1;
        }
    }

    &__range {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px;
        width: calc(100% - #{$menu});

        input {
            width: 100%;
        }
        label {
            display: block;
        }
    }

    &__controls {
        position: absolute;
        top: 0;
        right: 0;
        width: $menu;
        height: 100vh;
        padding: 50px 20px 20px;
        background-color: rgba($color-black, 0.85);

        .wrap {
            border-top: 1px solid $color-pale;
            margin-bottom: 15px;
            padding-top: 15px;

            &:first-of-type {
                padding-top: 0;
                border-top: none;
            }
        }

        .autoplay-button {
            display: block;
            margin: 0 auto;
            height: 50px;
            text-align: center;

            .circle {
                display: block;
                margin: 0 auto;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: $color-red;
            }

            &:hover {
                .circle {
                    opacity: 0.7;
                }
            }
        }
    }

    &__popup {
        position: absolute;
        // bottom: $margin * 3;
        // right: $menu + $margin;
        top: 0;
        left: 0;
        max-width: 312px;
        width: fit-content;
        height: fit-content;
    }

    &__description {
        width: 334px;
        height: 176px;
        position: absolute;
        inset: 32px 92px auto auto;

        @include breakpoint(not-desktop) {
            top: calc(#{$header-height} + 5px);
            left: 0;
        }
    }

    &__marker {
        @include flex();
        width: 40px;
        height: 1px;
        position: relative;

        svg {
            position: absolute;
            inset: 0;
            transform: translateY(-100%);
            width: 40px;

            @include breakpoint(not-desktop) {
                width: vw(40, phone);
            }
        }
    }


    &__label {
        font-size: 14px;
        white-space: nowrap;
        position: relative;
        color: $color-graphite;
        font-weight: 700;
        height: 100%;
        width: 100%;
        pointer-events: auto;

        button,
        a {
            @include flex(row, center, center);
            width: 100%;
            height: 100%;
            gap: vw(7);
            padding: 2px 6px 6px;
            cursor: pointer;

            div {
                @include flex(row, center, center);
                gap: 5px;

                svg {
                    margin-top: 3px;
                }
                //transform: rotate(-90deg) translateX(-8px) translateY(-1px);
            }

            @include hover {
                .svg-dropdown-2 {
                    transform: translateY(-3px);
                }
                .is-extra-visible & {
                    .svg-dropdown-2 {
                        transform: rotate(180deg) translateY(-3px);
                    }
                }
            }
        }

        .svg-download {
            position: relative;
            top: 1px;
        }

        .svg-dropdown-2 {
            position: relative;
            display: inline-block;
            margin-right: 7px;
            transform-origin: center;
            transition: transform 0.2s $ease-custom;

            path {
                stroke: currentColor;
            }
        }

        .is-extra-visible & {
            .svg-dropdown-2 {
                transform: rotate(180deg);
            }
        }
    }


    &__labels {
        position: absolute;
        right: 0;
        top: 0;
        
        @include breakpoint(not-desktop) {
            display: none;
        }
    }


    &__labels-outer {
        position: absolute;
        inset: auto 0 0 0;
        width: 100vw - vw($panel);
        height: fit-content;
        transition: transform 1s $ease-custom;

        body.is-extra-visible & {
            transform: translateY(calc(#{var(--extra-height)} * -1));
        }
    }


    &__info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        @include font-size(18, 1);
        color: $color-orange;
        font-weight: 800;
        padding: 10px 16px;
        padding-bottom: 8px;
        white-space: nowrap;
        
        @supports (-webkit-touch-callout: none) {
            padding-bottom: 12px;
        }

        &::after {
            content: "";
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            border: 2px solid $color-orange;
            transition: all 0.55s $ease-custom;
        }

        .is-map-usable-single &,
        .is-zoom-high &,
        body:not(.is-heavy-layer) &,
        body:not(.is-init-animation) & {
            display: none;
        }
    }


    .mapboxgl-ctrl-bottom-right,
    .mapboxgl-ctrl-bottom-left {
        visibility: hidden;
    }

}

.mapboxgl-ctrl-group {
    position: absolute;
    top: 69px;
    box-shadow: none !important;
    background: $color-white;
    // border: 1px solid rgba($color-graphite, 0.2);
    transition: opacity 0.5s linear;
    opacity: 0;
    width: 29px;
    height: 61px;
    border-radius: 4px;

    @include breakpoint(not-desktop) {
        display: none;
    }

    body.is-map-extended & {
        opacity: 1;
    }

    .mapboxgl-ctrl-icon {
        position: relative;
        background-image: none !important;

        &:before, &::after {
            position: absolute;
            top: 50%;
            left: 49%;
            transform: translate(-50%, -50%);
            content: '';
            display: block;
            width: 13px;
            height: 1px;
            background: $color-menu-text;
        }

        &:after {
            transform-origin: center;
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    .mapboxgl-ctrl-zoom-out {
        span {
            &:after {
                display: none;
            }
        }
    }

    @include breakpoint(desktop) {
        right: calc(16.5%);
    }
}

.mapboxgl-ctrl-group button {
    height: 50%;
}

.mapboxgl-ctrl-group button+button {
    border-top: 1px solid rgba($color-graphite, 0.2);

}