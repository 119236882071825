.heading {
    position: absolute;
    top: 30px;
    right: 12%;
    max-width: vw(495);
    @include z-index(content);
    pointer-events: all;
    transition: height 0.5s $ease-out;

    @include breakpoint(desktop) {

        height: vw(115);

        .list__heading-wrapper & {
            position: static;
            grid-row: 1;
            grid-column: 3;
        }

        &.is-reversed {
            .heading__label {
                margin-top: 0;
            }
        }

        .text {
            height: 100%;
        }

        
    }

    &__label {
        transition: opacity 0.5s $ease-out;
    }
    
    .list__heading-wrapper.is-wrapped & {
        height: vw(67);
        
        .heading__label {
            opacity: 0 !important;
        }
    }
    
    
    @include breakpoint(not-desktop) {
        height: auto;
        position: relative;
        top: unset;
        right: unset;
        
        .text {
            height: vw(125, phone);
            transition: height 0.5s $ease-out;
            
            .list__heading-wrapper.is-wrapped & {
                height: vw(67, phone);
            }
        }

        &__label {
            display: inline-block;
            @include font-size(14, 18);
        }
    }

    @include breakpoint(tablet) {
        
        .text {
            height: vw(110, tablet);
            
            .list__heading-wrapper.is-wrapped & {
                height: vw(67, tablet);
            }
        }

        &__label {
            display: inline-block;
            @include font-size(14, 18);
        }
    }


    &__copy {
        margin-top: vw(5) !important;
        @include font-size(16);
        width: vw(300);

        @include breakpoint(not-desktop) {
            margin-top: vw(10, phone) !important;
            width: vw(287, phone);
        }

        @include breakpoint(tablet) {
            width: vw(320, tablet);
            font-size: 16px !important;

        }
    }

    &__back {
        font-weight: 700;
        margin-top: 30px;
        @include font-size(14, 18);

        @include breakpoint(not-desktop) {
            margin-top: vw(17, phone);
        }

        @include breakpoint(tablet) {
            @include font-size(16, 20);
        }

        &::before {
            content: "";
            display: inline-block;
            width: vw(6);
            height: vw(6);
            transform: rotate(45deg) translateY(-2px);
            transition: $time-fast transform;
            margin-right: 5px;
            border-left: 1px solid $color-orange;
            border-bottom: 1px solid $color-orange;

            @include breakpoint(not-desktop) {
                width: vw(6, phone);
                height: vw(6, phone);
            }
        }

        @include hover {
            &::before {
                transform: rotate(45deg) translateY(1px) translateX(-3px);
            }
        }
    }

    &__value {
        display: block;
    }


    &__title-extended {
        @include flex(row, flex-start, center);

        .border-tooltip {
            position: relative;
            margin-top: vw(2);
            margin-left: vw(10);
        }

        @include breakpoint(not-desktop) {
            margin-bottom: vw(5, phone);
        }
    }


    &--mobile-relative {
        @include breakpoint(not-desktop) {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: vw(30, phone);
        }

        @include breakpoint(tablet) {
            margin-bottom: vw(35, tablet);
        }
    }
}
