.stripes {
    @include full;
    // overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
            to left,
            $color-gray-medium 0px,
            $color-gray-medium 1px,
            transparent 1px,
            transparent 4px
        );
    }

    &__bar {
        @include full;
        width: 0;
        right: -10px;
        left: auto;
        background-color: $color-gray-medium-darker;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -10px;
            width: 10px;
            height: 100%;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            background-color: $color-gray-medium-darker;
        }
    }
}