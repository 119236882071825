.summary {
    padding-left: vw(45);
    color: $color-gray-light-2;
    position: relative;

    &__svg {
        border-bottom: 1px solid $color-panel-text;
    }


    &__row {
        display: flex;
        justify-content: space-between;
        margin-top: vw(3);
        @include font-size(10, 10);
    }


    &__column {
        @include full;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: vw(20);
        @include font-size(10, 10);
        padding-left: vw(20);
    }


    &__info {
        position: absolute;
        left: 0;
        bottom: vw(5);
        transform: rotate(-90deg);
        transform-origin: 0% 0%;
        @include font-size(10, 9);
        color: $color-text;

        &::after {
            content: "#";
            position: absolute;
            top: 0;
            left: vw(-8);
        }
    }
}