.decoration {
    position: absolute;
    left: 0;
    top: 1%;
    width: vw(6);
    height: 100%;
    transform-origin: top;
    background: $color-orange;

    @include breakpoint(not-desktop) {
        width: vw(6, phone);
    }
    
    @include breakpoint(tablet) {
        width: vw(6, tablet);
    }

    &--long {
        height: 140%;
        bottom: 18%;
        top: unset;

        @include breakpoint(desktop) {
            body.is-mac & {
                bottom: 5%;
            }
        }

        @include breakpoint(tablet) {
            @supports (-webkit-touch-callout: none) {
                bottom: 5%;
            }
        }
    }

    &--horizontal {
        width: 123px;
        height: 10px;
        // top: auto;
        // bottom: -35px;
        // left: calc(50% - 62px);
        position: static;
        inset: 0;
        margin-left: auto;
        margin-right: auto;
        
        & + * {
            margin-top: vw(30);
        }

        @include breakpoint(not-desktop) {
            margin: vw(18, phone) 0 !important;
        }
    }

    .text--medium & {
        @include breakpoint(phone) {
            display: none;
        }
    }
}
