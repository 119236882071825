.border-tooltip {
    @include flex(row, flex-start, center);
    font-weight: 400;
    position: absolute;
    right: 0;
    height: vw(15);
    padding-left: vw(8);
    justify-self: flex-end;
    border: 1px solid $color-tooltip;
    border-radius: vw(10);
    text-transform: uppercase;
    gap: vw(4);
    z-index: 2;
    cursor: pointer;

    @include breakpoint(not-desktop) {
        border: none;
        position: static;
    }
    
    p {
        @include font-size(10, 1);

        @include breakpoint(not-desktop) {
            display: none;
        }
    }

    &--no-border {
        padding: 0;
        display: inline-flex;
        // position: relative;
        border: none;
        bottom: -3px;
        right: vw(-20);

        .test-legend__title & {
            bottom: 0;
        }
    }

    svg {
        height: vw(15);
        width: auto;
        position: relative;
        right: vw(-2);
        pointer-events: none;

        circle {
            fill: $color-tooltip;
        }

        @include breakpoint(not-desktop) {
            top: vw(1, phone);
            width: vw(17, phone);
            height: vw(17, phone);
        }
        
        @include breakpoint(tablet) {
            top: vw(1, tablet);
            width: vw(17, tablet);
            height: vw(17, tablet);    
        }
    }

    &.is-reversed {
        padding-left: 0;
        padding-right: vw(8);

        svg {
            right: unset;
            left: vw(-1);
        }
    }

    &__popup {
        @include font-size(12);
        text-transform: none;
        position: absolute;
        width: vw(240);
        padding: vw(12);
        background: #EFEDE8;
        border-radius: 6px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s $ease-out;

        @include breakpoint(not-desktop) {
            width: vw(240, phone);
            padding: vw(12, phone);
        }
        
        @include breakpoint(tablet) {
            width: vw(240, tablet);
            padding: vw(12, tablet);    
        }

        h2 {
            @include font-size(12);
            margin-bottom: vw(3);
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            background: inherit;
            width: vw(15);
            height: vw(15);
        }

        &.is-left {
            left: 0;
            transform: translateX(calc(-100% - vw(15)));

            &:after {
                right: 0;
                top: 50%;
                transform: translate(50%, -50%) rotate(45deg);
            }
        }

        &.is-up {
            top: 0;
            left: 100%;
            transform: translate(-53%, calc(-100% - vw(15)));

            &:after {
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 50%) rotate(45deg);
            }

            @include breakpoint(not-desktop) {
                top: vw(-8, phone);
                left: calc(100% - vw(10));
            }
            
            @include breakpoint(tablet) {
                top: vw(-8, tablet);
            }
        }

        &.is-down {
            bottom: 0;
            transform: translate(-53%, calc(100% + vw(15)));

            &:after {
                left: 50%;
                top: 0;
                transform: translate(50%, -50%) rotate(45deg);
            }
        }

        &.is-popup-visible {
            opacity: 1;
        }
    }

    @include breakpoint(not-desktop) {
        &--button-only {
            svg {
                path {
                    fill: $color-orange;
                }
            }
        }
    }

    @include hover {
        .border-tooltip__popup {
            opacity: 1;
        }
    }
}