.modal {

    @include breakpoint(desktop) {
        display: none;
    }

    background: $color-white;
    border-radius: 6px;
    width: vw(334, phone);
    padding: vw(20, phone);

    &__top-bar {
        @include flex(row, flex-start, center);
        gap: vw(12, phone);

        h2 {
            @include font-size(24);
            color: $color-orange;
        }
    }

    &__content {
        margin-top: vw(10, phone);
        color: $color-orange;
    }

    .button {
        height: vw(30, phone);
        width: 100%;
    }
}