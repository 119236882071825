.searchbar {
    position: relative;
    width: 100%;

    &__form {
        @include flex(row, space-between, center);
        padding: 0 vw(15) 0 vw(12);
        width: 100%;
        height: vw(36);
        background: $color-white;
        border: 1px solid $color-graphite;
        border-radius: 6px;
        color: $color-graphite;
        position: relative;
        @include font-size(18, 1);
        font-weight: 700;
        z-index: 2;
        
        body.is-conus & {
            border: 1px solid $color-panel-text;
            background: $color-gray-light-3;
        }
        
        @include breakpoint(not-desktop) {
            height: vw(44, phone);
            padding: 0 vw(15, phone) 0 vw(12, phone);
        }
        
        @include breakpoint(tablet) {
            @include font-size(18, 1);
            height: vw(55, tablet);
            padding: 0 vw(15, tablet) 0 vw(18, tablet);
            border-radius: 12px;
        }

        svg {

            path,
            circle {
                stroke: $color-graphite;
            }

            path {
                stroke-dasharray: 8;
            }

            circle {
                stroke-dasharray: 51;
            }
        }

        input {
            position: relative;
            border-width: 0;
            background: transparent;
            color: $color-graphite;
            outline: none;
            top: -1px;
            width: 100%;
            padding-right: vw(40);
            transition: border-width 0.5s linear;

            @include breakpoint(not-desktop) {
                padding-left: 0;
            }

            &::placeholder {
                color: $color-graphite;
            }

            &:not(:placeholder-shown) ~ label span {
                visibility: hidden;
            }
        }

        label {
            @include flex(row, space-between, center);
            position: absolute;
            padding: 0 0 0 vw(13);
            inset: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;

            * {
                pointer-events: none;
            }

            span {
                position: relative;
                top: -1px;
            }
        }

        body.has-current-coord & {
            background: white;
            border-color: $color-graphite;
        }

        body.is-conus & {
            background: $color-gray-light-3;
            border-color: $color-panel-text;
        }
    }


    &__icon {
        @include flex(row, flex-end, center);
        height: 100%;
        padding: 0 vw(12);
        
        @include breakpoint(not-desktop) {
            padding: 0 vw(12, phone);
        }
        
        @include breakpoint(tablet) {
            padding: 0 vw(16, tablet);
        }
        
        svg {
            width: vw(13);

            body.is-conus & {
                opacity: 0.6;
            }
            
            @include breakpoint(not-desktop) {
                width: vw(20, phone);
            }
            
            @include breakpoint(tablet) {
                width: vw(24, tablet);
            }
        }
    }
}
