.test {
    &__heading-wrapper {
        @extend .list__heading-wrapper;
        padding-top: vw(40);
        @include flex(column, flex-start, flex-end);
        gap: vw(20);
        pointer-events: all;

        @include breakpoint(not-desktop) {
            height: vw(185, phone);
        }
    }
    
    &__heading {
        margin-right: vw(100);
        
        @include breakpoint(not-desktop) {
            width: 100%;
            margin: 0;
        }
    }

    &__legend {
        position: absolute;
        bottom: vw(20);
        left: vw(20);
        
        @include breakpoint(not-desktop) {
            left: vw(20, phone);
            right: vw(20, phone);
            top: vw(-30, phone);
            bottom: unset;
            transform: translateY(-100%);
        }
    }

    &__back {
        @include flex(row, flex-start, center);
        gap: vw(5);
        @include font-size(14);
        position: fixed;
        left: 0;
        top: vw(166);
        padding: vw(12) vw(10) vw(10);
        background: $color-white;
        font-weight: 700;
        z-index: -2;
        border-radius: 0 0 6px 0;
        pointer-events: auto;
        transition: background 0.3s $ease-out, color 0.3s $ease-out;

        svg {
            position: relative;
            top: 1px;
            width: vw(26);
            transform: rotate(90deg);
            
            path {
                transition: stroke 0.3s $ease-out;
            }
        }

        @include hover {
            background: $color-orange;
            color: $color-white;
            font-weight: 700;

            svg {
                path {
                    stroke: $color-white;
                }
            }
        }
    }
}