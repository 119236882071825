.bar {
    @include flex(row, space-between, center);
    @include font-size(16, 1);
    position: relative;
    width: vw(380);
    height: 100%;
    /* padding-right: 17px;
    padding-left: 18px; */
    border-radius: 6px;
    color: $color-panel-text;
    background: $color-white;
    font-family: "TEG", sans-serif;
    
    @include breakpoint(not-desktop) {
        display: none;
        min-width: unset;
        height: 50px;
        width: 100%;
        border-radius: 15px;
    }

    @include breakpoint(tablet) {
        max-width: 85%;
        margin: 0 auto;
        padding: 3px 15px;
    }

    &__button {
        @include font-size(18);
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__toggle {
        // margin-left: vw(22);
        width: vw(26);

        svg {
            path {
                stroke: currentColor;
            }
        }

        @include breakpoint(not-desktop) {
            position: absolute;
            right: 13px;
            top: 35%;
        }
    }

    &__dropdown {
        width: 100%;
        height: vw(320);
        position: absolute;
        bottom: vw(10);
        transform: translateY(100%);
        z-index: -1;
        clip-path: inset(0 0 0 0);
        pointer-events: none;
    }

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        background-color: $color-orange;
        z-index: -1;
        pointer-events: none;
        transition: background-color 0.6s $ease-out;
        border-radius: 56px;

        @include breakpoint(not-desktop) {
            border-radius: 14px;
        }
    }
}
