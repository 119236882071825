@keyframes svg-list {
    0% {
        stroke-dasharray: 20;
        stroke-dashoffset: 20;
    }

    30% {
        stroke-dashoffset: 0;
    }

    55% {
        stroke-dasharray: -20;
        stroke-dashoffset: 0;
    }

    90% {
        stroke-dashoffset: -20;
    }

    100% {
        stroke-dashoffset: -20;
    }
}

@keyframes svg-lines {
    0% {
        stroke-dashoffset: 0;
    }

    20% {
        stroke-dashoffset: 50;
    }

    25% {
        opacity: 0;
        stroke-dashoffset: -50;
    }

    26% {
        opacity: 1;
        stroke-dashoffset: -50;
    }

    50% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeVideo {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes moveArrRight {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(3px);
    }

    40% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes moveArrLeft {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-3px);
    }

    40% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
}

@keyframes draw-loader {
    from { stroke-dashoffset: 182px * 3; }
    to { stroke-dashoffset: 182px; }
  }


@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(-40px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}