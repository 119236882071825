.date {
    position: relative;
    @include flex(row, center, center);
    @include font-size(14, 14);
    width: 100%;
    height: fit-content;
    // padding: 0 vw(10);
    color: $color-graphite;
    background-color: $color-white;
    border-radius: 6px;
    border: 1px solid $color-graphite;
    height: vw(30);
    user-select: none;
    // margin: 0 auto;
    // cursor: pointer;
    visibility: hidden; // Temporary
    text-transform: uppercase;
    
    @include breakpoint(not-desktop) {
        margin-top: vw(28, phone);
        height: vw(28, phone);
        padding: vw(3, phone) vw(15, phone);
    }
    
    @include breakpoint(tablet) {
        height: vw(40, tablet);
        margin-top: vw(36, tablet);
        @include font-size(16, 1);
    }

    &.is-chosen {
        visibility: visible;
    }

    body:not(.has-current-coord) & {
       // opacity: 0;
    }

    @include hover {
        .date__arrow .svg-arrow {
            transform: $chevron-hover;
        }

        body.is-calendar-open & {
            .date__arrow .svg-close {
                transform: rotate(180deg);
            }
        }
    }

    &__arrow {
        @include flex(row, center, center);
        flex: 0.25;
        height: 100%;
        border-left: 1px solid $color-graphite;

        .svg-close {
            display: none;
            width: vw(10);
            transition: $chevron-transition;

            body.is-calendar-open & {
                display: block;
            }
        }
        
        .svg-arrow {
            display: block;
            width: vw(15);
            transition: $chevron-transition;

            body.is-calendar-open & {
                display: none;
            }
        }
        
        @include breakpoint(not-desktop) {
            display: none;
        }
    }

    &__sep {
        @include flex(row, center, center);
        height: 100%;
        width: auto;
        padding: 0 vw(15);
        border-left: 1px solid $color-graphite;
        border-right: 1px solid $color-graphite;

        span {
            width: vw(5);
            height: 1px;
            background-color: $color-graphite;

            @include breakpoint(not-desktop) {
                width: vw(10, phone);
            }
        }

        @include breakpoint(not-desktop) {
            width: vw(25, phone);
            margin: 0 vw(7, phone);
        }
    }

    &__item {
        @include flex(row, flex-start, center);
        padding: 0 vw(10) vw(1);
        white-space: nowrap;
        flex: 1;
        @include font-size(15, 20);

        input {
            border: none;
            width: vw(80);
            font-weight: 700;
        
            &:focus {
                outline: none;
            }
        }

        strong {
            @include breakpoint(desktop) {
                font-weight: 700;
            }
        }

        span {
            display: inline-block;
        }
    }

    &__toggle {
        @include font-size(14, 20);
        @include flex(row, space-around, center);
        position: absolute;
        width: auto;
        height: vw(23);
        padding: 0 0 vw(1) vw(8);
        gap: vw(8);
        border-radius: 6px;
        background-color: $color-white;
        border: 1px solid $color-panel-text;
        visibility: visible;
        
        body.is-conus &, .is-states &, body:not(.has-current-coord) & {
            color: $color-gray-light-2;
            background-color: $color-gray-light-3;
            border: 1px solid $color-panel-text;
            pointer-events: none;
        }

        .date.is-chosen & {
            visibility: hidden;
        }

        @include breakpoint(not-desktop) {
            border-radius: vw(14, phone);
            padding: vw(5, phone) vw(10, phone) vw(3, phone);
            width: vw(196, phone);
            height: vw(28, phone);
            
            @supports (-webkit-touch-callout: none) {
                padding: vw(3, phone) vw(10, phone) vw(5, phone);

            }
        }
        
        @include breakpoint(tablet) {
            width: vw(250, tablet);
            height: vw(42, tablet);
        }

        span {
            @include flex();
            width: vw(25);
            height: 100%;
            border-left: 1px solid $color-panel-text;
        }

        svg {
            transition: $chevron-transition;
            width: vw(15);
            // padding: vw(1) vw(5) vw(2);

            path {
                stroke: currentColor;
                // stroke-width: 2px;
            }
            
            @include breakpoint(not-desktop) {
                @supports (-webkit-touch-callout: none) {
                    margin-top: vw(2, phone);
                }
            }
        }

        @include hover {
            svg {
                transform: $chevron-hover;
            }
        }
    }

    &__hour {
        font-weight: 700;
    }
}
