$color-white:               #fff;
$color-black:               #000;
$color-pale:                #fefefe;
$color-theme:               #d6dadb;
$color-gray:                #c7c8cb;
$color-red:                 #aa1313;
$color-orange:              #F96241;
$color-graphite:            #474747;
$color-gray-light:          #efefef;
$color-gray-light-2:        #a1a1a1;
$color-gray-light-3:        #f1f1f1;
$color-gray-medium:         #dbd9d7;
$color-gray-medium-darker:  #C1C1C1;
$color-gray-deposiotion:    #B6B6B6;
$color-gray-list:           #D8D8D8;

$color-bg:                   $color-gray-light-3;
$color-text:                 $color-graphite;

// Menu
$color-menu-bg:             $color-white;
$color-menu-footnote:       #7D7D7D;
$color-menu-text:           #474747;

// Panel
$color-panel-bg:           $color-white;
$color-panel-text:          #DBD9D7;
$color-map-label:          $color-white;

// Tooltip
$color-tooltip:            #6C6C6C;

// Dropdown
$color-dropdown-bg:         #EFEFEF;
$color-dropdown-item-stroke:#DBD9D7;
$color-dropdown-border:     #CECDCB;

// Scroller
$color-scroller-stroke:     #DBD9D7;
$color-scroller-toggle:     $color-orange;

// Popup
$color-popup-text:          #474747;
$color-popup-bg:            #EFEFEF;
$color-popup-shadow:        hsla(0, 0%, 0%, 0.14);


// Heatmap and icon colors
$color-heatmap-red:         #E84646;
$color-heatmap-orange:      #FB8D14;
$color-heatmap-yellow:      #FBCA14;
$color-heatmap-green:       #92C228;

// Calendar
$color-calendar-bg:         #DBD9D7;
$color-calendar-inner:      #EFEFEF;
$color-calendar-text:       #474747;
$color-calendar-disabled:   #CECECE;
$color-calendar-pick:       $color-orange;