.range-widget {

    width: fit-content;
    height: vw(50, phone);
    border-radius: vw(8, phone);
    background: $color-orange;
    color: $color-white;
    padding: vw(8, phone);
    @include font-size(14, 16);
    position: absolute;
    top: vw(-110, phone);
    left: vw(-13, phone);

    @include breakpoint(desktop) {
        display: none;
    }

    p {
        text-transform: uppercase;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: vw(-3, phone);
        right: calc(50% - vw(8, phone));
        background: inherit;
        width: vw(16, phone);
        height: vw(16, phone);
        transform: rotate(45deg);
        z-index: -1;

        @include breakpoint(tablet) {
            top: calc(50% - vw(8, tablet));
            width: vw(16, tablet);
            height: vw(16, tablet);
        }
    }

    strong {
        white-space: nowrap;
    }
}