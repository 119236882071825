
.tooltip {
    position: absolute;
    inset: 0 auto auto 100%;
    width: vw(13);
    height: vw(13);

    @include hover {
        
        .tooltip__text {
            opacity: 1;
        }
    }


    &__inner {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__icon {
        @include flex(row, center, center);
        @include font-size(9, 1);
        width: 100%;
        height: 100%;
        color: $color-white;
        font-weight: 400;

        svg {
            width: vw(11);
        }
    }


    &__text {
        @include font-size(10, 12);
        font-weight: 400;
        color: #474747;
        padding: vw(12) vw(10) vw(13) vw(14);
        background-color: #EFEFEF;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        position: absolute;
        inset: auto auto 100% 100%;
        margin-left: vw(-2);
        margin-bottom: vw(-2);
        pointer-events: none;
        transition: opacity 0.25s ease-out;
        opacity: 0;
    }
}