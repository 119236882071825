.filter {
    position: relative;
    padding: 0 2px 0 4px;
    padding-bottom: vw(25);
    
    .touchevents & {
        padding-bottom: vw(50);
    }

    &__mask {
        @include flex();
        @include font-size(11);
        position: absolute;
        top: vw(-1);
        left: 0;
        height: vw(38);
        width: 100%;
        background: $color-gray-light-3;
        z-index: 2;
        text-align: center;
        border-radius: 6px;
        text-transform: uppercase;
        display: none;
        pointer-events: none;
        
        body.is-conus &, .is-states &, body:not(.has-current-coord) & {
            pointer-events: auto;
            display: flex;
        }
    }
}
