.filter-order {
    overflow: hidden;
    position: absolute;
    right: 0;
    top: vw(28);
    z-index: 2;
    // inset: vw(20) 0 auto;
    background-color: $color-white;
    padding: vw(5) vw(10);
    color: $color-calendar-text;
    border: 1px solid $color-graphite;
    border-radius: 6px;
    cursor: initial;
    $radius-mobile: vw(8, phone);

    .sort-list & {
        top: vw(20);
        left: -1px;
        right: -1px;
        padding: vw(18) vw(12) vw(8);
        z-index: -1;
        font-weight: 400;
        visibility: hidden;
    }

    .sort-list.is-open & {
        visibility: visible;
    }

    @media (min-width: $calendar-hd-bp) {
        padding: vw(12) vw(9) vw(9);
    }

    @include breakpoint(not-desktop) {
        padding: 0;
        background-color: $color-menu-bg;
        inset: unset;
        top: vw(-50, phone);
        left: 0;
        right: 0;
        border-radius: $radius-mobile;

        .sort-list & {
            top: vw(-140, phone);
            padding-bottom: vw(30, phone);
        }
    }
    
    @include breakpoint(tablet) {
        padding: 0 vw(7, tablet) 0;
        top: vw(-90, tablet) !important;
        
        .sort-list & {
            top: vw(-240, tablet) !important;
            padding-bottom: vw(30, tablet);
        }
    }

    .calendar__close {
        position: relative;
        display: block;
        &::after {
            content: "";
            position: absolute;
            top: -50%;
            left: -50%;
            width: 200%;
            height: 200%;
        }

        @include breakpoint(not-desktop) {
            display: none;
        }
    }

    &__item {
        @include flex(column, flex-start, flex-start);
        // margin-bottom: vw(10);
        transition: color 0.3s $ease-out;

        @include breakpoint(not-desktop) {
            margin: 0;
        }

        @include hover {
            color: $color-orange;
        }
    }

    &__form {
        @include flex(column, flex-start, flex-start);

        label {
            position: relative;
            // padding-left: vw(24 + 12);
            cursor: pointer;
            @include font-size(14, 28);

            @include breakpoint(not-desktop) {
                width: 100%;
                padding: vw(6, phone) vw(14, phone);

                &:not(&:last-of-type) {
                    border-bottom: 1px solid $color-gray-medium;
                }
            }

            @include breakpoint(tablet) {
                padding: vw(17, tablet) 0 vw(19, tablet) vw(20, tablet);
            }

            @include breakpoint(hd) {
                @include font-size(14, 24);

            }

            @include hover {
                &::before {
                    transform: scale(.9);
                }
            }

            @include breakpoint(not-desktop) {
                @include font-size(14, 20);
                font-weight: 400;
            }

            @include breakpoint(tablet) {
                @include font-size(16, 20);
            }
        }

        input {
            @extend .visually-hidden;
        }

        input:checked + label {
            &::after {
                transform: scale(1);
            }
        }
    }


}