
.react-calendar {
    background-color: transparent;
    color: $color-calendar-text;
    border-radius: 6px;
    margin-bottom: vw(12);

    @include breakpoint(not-desktop) {
        padding: vw(14, phone);
    }


    abbr {
        text-decoration: none;
    }


    &__viewContainer {

        *:disabled {
            opacity: 0.25;
        }
    }


    &__navigation {
        @include flex(row, space-between, center);
        margin-bottom: vw(14);

        &__label {
            @include font-size(14, 16);
            font-weight: 700; 
            
            @include breakpoint(tablet) {
                @include font-size(18, 20);
            }
        }

        &__next2-button,
        &__prev2-button {
            display: none;
        }

        &__next-button,
        &__prev-button {
            
            svg {
                width: vw(17);

                path {
                    stroke-width: 2px;
                }

                @include breakpoint(not-desktop) {
                    width: vw(20, phone);
                }
            }

            &:disabled {
                opacity: 0.25;
            }
        }

        &__next-button {

            svg {
                transform-origin: center;
                transform: rotate(180deg);
            }
        }
    }


    &__tile:not(.react-calendar__month-view__days__day) {
        @include font-size(14, 18);      
        @include flex();
        height: vw(30);  
        margin: vw(10) 0;
        border-radius: 6px;

        @include hover {
            background: $color-orange;
            color: $color-white;
            font-weight: 700;
        }
    }


    &__month-view {


        &__weekdays {
            display: none !important;
        }


        &__days {


            &__day {
                @include font-size(12, 40);
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    inset: 1px 0 0 0;
                    margin: auto;
                    border-radius: 6px;
                    z-index: 0;
                    opacity: 1;
                    width: vw(28);
                    height: vw(28);
                    background-color: $color-orange;
                    transition: color 0.35s $ease-custom; 
                    opacity: 0;
                }

                @media (min-width: $calendar-hd-bp) {
                    @include font-size(12, 30);
                    
                    &::before {
                        width: vw(25);
                        height: vw(25);
                    }
                }
                
                @include breakpoint(not-desktop) {
                    &:before {
                        width: vw(25, phone);
                        height: vw(25, phone);
                    }
                }
                
                @include breakpoint(tablet) {
                    @include font-size(14, 40);
                    &:before {
                        width: vw(30, tablet);
                        height: vw(30, tablet);
                    }
                }

                abbr {
                    position: relative;
                    z-index: 1;
                }

                @include hover {

                    abbr {
                        font-weight: 700;
                        color: $color-white;
                    }

                    &::before {
                        opacity: 1;
                    }
                }


                &.react-calendar__tile--active {


                    abbr {
                        font-weight: 700;
                        color: $color-white;
                    }

                    &::before {
                        opacity: 1;
                    }
                }

            
                &--neighboringMonth {
                    visibility: hidden !important;
                }
            }
        }
    }
}