.clear {
    @include flex();
    @include font-size(14, 12);
    height: vw(24);
    padding-left: vw(6);
    gap: vw(6);
    border: 1px solid $color-panel-text;
    border-radius: 6px;
    margin-bottom: vw(10);
    transition: opacity 0.5s $ease-out;

    > span {
        height: 100%;
        padding: vw(5);
        display: block;
        border-left: 1px solid $color-panel-text;

        span {
            display: block;
            transform: scale(0.6);
        }
    }

    body.is-conus & {
        opacity: 0 !important;
        pointer-events: none;
    }
}