.extra {
    background: $color-white;
    width: $extra-width;
    padding: 50px 50px 20px 20px;

    &__outer {
        position: absolute;
        top: 25px;
        right: 31.5%;
        border-radius: 6px;
        overflow: hidden;
        pointer-events: all;
        transition: all 0.5s $ease-out;
        width: 180px;
        height: 30px;
    }

    &__wrap {
        font-size: 14px;
    }

    @include breakpoint(not-desktop) {
        display: none;
    }
}