.icon-close {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    transform: rotate(0);
    transition: transform $time-fast $easing, opacity $time-fast $easing;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 20px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -1px -10px;
        background: currentColor;
    }

    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(135deg);
    }
}

%icon-close-hover {
    transform: rotate(-90deg);
}

.icon-carrot {
    position: relative;
    display: inline-block;
    width: 7px;
    height: 5px;
    transition: transform $time $ease-custom;
    transform-origin: 66% 50%;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: currentColor transparent transparent transparent;
        transition: transform $time-fast $ease-custom;
    }
}
%icon-carrot-hover {
    &::after {
        transform: translateY(3px);
    }
}

.icon-dropdown {
    position: relative;
    display: inline-block;
    height: 15px;
    width: 20px;
    vertical-align: middle;
    transform: translateY(0);
    transition: transform 0.6s $ease-out-back 0.1s;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        transition: transform $time-fast $easing, opacity $time-fast $easing;
        border-style: solid;
    }

    &::before {
        border-width: 1px 0 0 1px;
        border-color: currentColor transparent transparent currentColor;
        transform: translateY(5px) rotate(45deg);
        transform-origin: 8px 4px;
        opacity: 0;
    }

    &::after {
        top: 0;
        border-width: 0 1px 1px 0;
        border-color: transparent currentColor currentColor transparent;
        transform: translateY(3px) rotate(45deg);
        transform-origin: 13px 9px;
    }
}

%icon-dropdown-open {
    &::after {
        transform: translateY(10px) rotate(45deg);
    }
}

%icon-dropdown-hover {
    transform: translateY(4px);
}

a,
button {
    &:hover {
        .icon-close {
            @extend %icon-close-hover !optional;
        }
    }
}
