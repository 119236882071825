$color-1: #FEFFA5;
$color-2: #F5B750;
$color-3: #A55E3A;
$color-4: #BD5322;
$color-5: #5D0F06;
$color-6: #000000;


.test-legend {
    &__title {
        display: inline-block;
        @include font-size(16);
        margin-bottom: vw(4);
        position: relative;
        font-weight: 700;
        
        @include breakpoint(not-desktop) {
            @include flex(row, space-between, center);
            @include font-size(12);
            font-weight: 400;
        }
    }

    &__bar {
        position: relative;
        width: max-content;
        @include flex();

        &::before, &::after {
            content: '';
            position: relative;
            top: vw(-3);
            display: block;
            width: 1px;
            height: vw(16);
            background-color: $color-graphite;
        }
        
        @include breakpoint(not-desktop) {
            margin-top: vw(10, phone);
            width: 100%;
            
            &:before, &:after {
                top: vw(-3, phone);
                height: vw(16, phone);
            }
        }

        span {
            @include font-size(10);
            position: absolute;
            right: vw(-10);
            bottom: vw(2);
            transform: translateX(100%);
        }
    }

    &__segment {
        position: relative;
        width: vw(50);
        height: vw(10);
        
        @include breakpoint(not-desktop) {
            flex: 1;
            height: vw(10, phone);
        }
        
        &::before {
            content: '';
            position: relative;
            display: block;
            bottom: vw(3);
            left: -1px;
            width: 1px;
            height: vw(13);
            background-color: $color-graphite;
            
            @include breakpoint(not-desktop) {
                width: vw(1, phone);
                bottom: vw(3, phone);
                height: vw(13, phone);
            }
        }

        span {
            @include font-size(10);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: vw(-13);
            
            @include breakpoint(not-desktop) {
                left: 50%;
                transform: translateX(-30%);
                bottom: vw(-15, phone);
            }
        }

        &--1 {
            background: $color-1;
        }

        &--2 {
            background: $color-2;
        }

        &--3 {
            background: $color-3;
        }

        &--4 {
            background: $color-4;
        }

        &--5 {
            background: $color-5;
        }

        &--6 {
            background: $color-6;
        }
    }
}