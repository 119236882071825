.toggle {
    position: relative;
    cursor: pointer;
    pointer-events: auto;
    &::before {
        content: "";
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
    }

    body.is-menu-animating & {
        pointer-events: none;
    }

    body:not(.is-menu-open) & {

        @include breakpoint(desktop) {

            &:hover {

                .hamburger {

                    path {
                        transition-delay: 0s;

                        &:nth-of-type(1) {
                            stroke-dashoffset: 10px;
                        }

                        &:nth-of-type(2) {
                            stroke-dashoffset: 20px;
                        }

                        &:nth-of-type(3) {
                            stroke-dashoffset: 10px;
                        }
                    }
                }

            }
        }
    }

    .arrow {
        position: absolute;
        top: 8px;
        left: 0;
    }

    &__hamburger {
        opacity: 1;
        pointer-events: all;
    }
}
